import { Component, OnInit, Input, Output, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import { ReportDocumentFooter } from '../../model/report-document-footer';
import { ReportDocumentParagraph } from '../../model/report-document-paragraph';

@Component({
  selector: 'app-document-viewer-footer',
  templateUrl: './document-viewer-footer.component.html',
  styleUrls: ['./document-viewer-footer.component.css']
})
export class DocumentViewerFooterComponent implements OnInit, AfterViewInit {

  @Input() reportFooter: ReportDocumentFooter;
  @Input() pageNumberParagraph: ReportDocumentParagraph;
  @Output() offsetHeight = new EventEmitter<number>();
  footerHeight: number;

  @ViewChild('footerDiv')
  footerDiv: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkElementHeight();
    });
  }

  checkElementHeight() {
    const div = this.footerDiv.nativeElement as HTMLDivElement;
    this.footerHeight = div.offsetHeight;
    this.offsetHeight.emit(this.footerHeight);
  }

}
