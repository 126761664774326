import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StateViewComponent } from './state-view/state-view.component';
import { StateCreateComponent } from './state-create/state-create.component';
import { StateUpdateComponent } from './state-update/state-update.component';
import { OrganizationViewComponent } from './organization-view/organization-view.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './organization-update/organization-update.component';
import { RoleViewComponent } from './role-view/role-view.component';
import { RoleCreateComponent } from './role-create/role-create.component';
import { RoleUpdateComponent } from './role-update/role-update.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { UserPwdComponent } from './user-pwd/user-pwd.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { UserRegisteredViewComponent } from './user-registered-view/user-registered-view.component';
import { UserRegisteredUpdateComponent } from './user-registered-update/user-registered-update.component';
import { DeficiencyTableComponent } from './deficiency-table/deficiency-table.component';

const routes: Routes = [
  { path: 'state-view', component: StateViewComponent },
  { path: 'state-create', component: StateCreateComponent },
  { path: 'state-update', component: StateUpdateComponent },
  { path: 'organization-view', component: OrganizationViewComponent },
  { path: 'organization-create', component: OrganizationCreateComponent },
  { path: 'organization-update', component: OrganizationUpdateComponent },
  { path: 'role-view', component: RoleViewComponent },
  { path: 'role-create', component: RoleCreateComponent },
  { path: 'role-update', component: RoleUpdateComponent },
  { path: 'user-view', component: UserViewComponent },
  { path: 'user-create', component: UserCreateComponent },
  { path: 'user-update', component: UserUpdateComponent },
  { path: 'user-pwd', component: UserPwdComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'user-registration', component: UserRegistrationComponent },
  { path: 'user-registered-view', component: UserRegisteredViewComponent },
  { path: 'user-registered-update', component: UserRegisteredUpdateComponent },
  { path: 'deficiency-table', component: DeficiencyTableComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemAdministrationRoutingModule { }
