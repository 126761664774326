import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Organization } from '../model/organization';
import { SelectItem, MessageService } from 'primeng/api';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { Subscription } from 'rxjs';
import { User } from '../model/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-pwd-forgot',
  templateUrl: './user-pwd-forgot.component.html',
  styleUrls: ['./user-pwd-forgot.component.scss']
})
export class UserPwdForgotComponent implements OnInit, OnDestroy {

  HOME_VIEW = '/';

  registered = false;
  emailChecked = false;
  emailSent = false;
  emailVerification: { email: string, used: boolean, checkCode: string };

  emailForm: FormGroup;
  mainForm: FormGroup;
  emailCheckForm: FormGroup;

  organizations: Organization[];
  organizationsItems: SelectItem[];
  organizationsSubsciption: Subscription;

  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public router: Router,
    public messageService: MessageService,
    public systemAdministrationService: SystemAdministrationService,
    public authenticationService: AuthenticationService
  ) { }


  ngOnInit(): void {
    this.initForms();
    this.systemAdministrationService.loadOrganizationsNoAuthorization();
    this.subscribeToOrganizations();
  }

  ngOnDestroy(): void {
    this.organizationsSubsciption.unsubscribe();
  }

  subscribeToOrganizations() {
    this.organizationsSubsciption = this.systemAdministrationService.organizationsSubjet.subscribe(data => {
      this.organizations = data;

      if (this.organizations) {
        this.organizationsItems = [];
        this.organizationsItems.push({ label: 'Select', value: null });

        this.organizations.forEach(item => {
          this.organizationsItems.push({
            label: item.name,
            value: item
          });
        });
      }

    });

    this.systemAdministrationService.emitOrganizationsSubject();
  }

  initForms() {

    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      emailConfirm: ['', [this.emailValidator]]
    });


    this.emailCheckForm = this.fb.group({
      email: new FormControl({ value: '', disabled: true }),
      verificationCode: ['', [Validators.required]]
    });

    this.mainForm = this.fb.group({
      email: new FormControl({ value: '', disabled: true }),
      passwd: ['', [Validators.required]],
      passwdConfirm: ['', [this.passwordValidator]]
    });

  }

  emailValidator(control: AbstractControl) {
    const email = control.root.get('email');
    const emailConfirm = control;
    return email && emailConfirm && email.value === emailConfirm.value ? null : { valid: false };
  }

  passwordValidator(control: AbstractControl) {
    const passwd = control.root.get('passwd');
    const passwdConfirm = control;
    return passwd && passwdConfirm && passwd.value === passwdConfirm.value ? null : { valid: false };
  }



  onCloseMessage() {
    this.messageService.clear('signupMsgKey');
  }

  onSendEmail() {
    const emailAdress = this.emailForm.value.email;
    this.systemAdministrationService.passwordResetCode(emailAdress).then(value => {
      this.emailVerification = value;

      // console.log(this.emailVerification);

      // is eamail adress used ?
      if (!this.emailVerification.used) {
        this.messageShowService.showSignupError('No user account is associated to this email adress !');
      } else {
        this.emailSent = true;
        this.emailCheckForm.patchValue({ email: emailAdress });
      }
    });
  }

  backToEmail() {
    this.emailSent = false;
  }

  onCheckEmail() {
    const verificationCode = this.emailCheckForm.value.verificationCode;
    if (this.emailVerification.checkCode === verificationCode) {
      this.emailChecked = true;
      this.mainForm.patchValue({ email: this.emailForm.value.email });
    } else {
      this.messageShowService.showSignupError('Invalid verification code !');
    }

  }

  onChangePasswd() {
    const email = this.emailForm.value.email;
    const password = this.mainForm.value.passwd;

    this.systemAdministrationService.resetPasswordForgot(email, password).then(() => {
      this.messageShowService.showSignupSuccess('Password changed successfully !');
      this.registered = true;
    }, () => {
      this.messageShowService.showSignupError('Failed to change password !');
    });
  }

  onClearEmail() {
    this.emailForm.patchValue({
      email: '',
      emailConfirm: ''
    });
  }

  onCancel() {
    this.mainForm.patchValue({
      passwd: '',
      passwdConfirm: ''
    });
  }

  onClose(){
     this.router.navigate([this.HOME_VIEW]);
  }
}