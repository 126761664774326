import { Injectable } from '@angular/core';
import { Authentication } from './model/authentication';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../shared/services/config.service';
import { HttpMapping } from '../shared/constants/http-mapping';
import { User } from '../system-administration/model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  protected backendServer = ConfigService.settings.backendServers;

  authentication: Authentication;

  authenticationSubjct = new Subject<Authentication>();

  jwtHelperService: JwtHelperService;

  signUp = false;


  HEADER_AUTHORIZATION = 'authorization';
  STORAGE_ITEM_TOKEN = 'token';
  OBSERVE_RESPONSE = 'response';


  constructor(
    private http: HttpClient
  ) {
    this.jwtHelperService = new JwtHelperService();
    this.checkAuthentication();
  }

  get isAuthenticated() {
    return this.authentication ? this.authentication.isAuthenticated : false;
  }

  get toSignUp() {
    return this.authentication ? this.authentication.isAuthenticated : false;
  }

  activateSignUp() {
    this.signUp = true;
  }

  deactivateSignUp() {
    this.signUp = true;
  }

  emitAuthenticationSubjctSubjct() {
    this.authenticationSubjct.next(this.authentication);
  }

  logout() {
    this.removeToken();
    this.authentication = null;
    this.emitAuthenticationSubjctSubjct();
  }

  /**
   * login() methode call the backend authentication service to authenticate the user with given credentials
   */
  login(credentials: { username: string, password: string }, remember: boolean): Promise<any> {
    this.removeToken();
    const route = HttpMapping.LOGIN_URL;

    // remote backend service call
    return new Promise((resolve, reject) => {

      this.proceedToLogin(route, credentials).subscribe(resp => {
        this.handleSignedIn(resp, remember);
        this.loadAuthenticatedUser();
        resolve(this.authentication);
      }, () => {
        reject();
      });
    });

  }

  loadAuthenticatedUser() {
    if (this.authentication.subject) {
      if (this.authentication.hasDefaultAdminRole) {
        this.authentication.user = {
          id: null,
          firstName: 'Default',
          lastName: 'Admin',
          email: null,
          password: null,
          title: 'System administrator',
          organization: null,
          roles: [],
          status: null
        };

      } else {
        const route = HttpMapping.USER_GET_AUTHENTICATED;
        this.http.get(this.createCompleteRoute(route, this.backendServer.aanddUrlAddress), this.generateAuthorizationHeader())
          .subscribe(data => {
            this.authentication.user = data as User;
            this.emitAuthenticationSubjctSubjct();
          });
      }

    }
  }

  public proceedToLogin(route: string, user: { username: string; password: string; }) {
    return this.http.post(this.createCompleteRoute(route, this.backendServer.aanddUrlAddress), user, { observe: 'response' });
  }

  // tslint:disable-next-line: ban-types
  handleSignedIn(resp: HttpResponse<Object>, remember: boolean) {
    const jwtToken = resp.headers.get(this.HEADER_AUTHORIZATION);
    if (remember) {
      this.saveToken(jwtToken);
    }
    this.parseToken(jwtToken);
  }


  parseToken(token: string) {
    this.resetAuthentication();
    if (token) {
      if (this.isTockenPasrsable(token) && !this.jwtHelperService.isTokenExpired(token)) {

        this.authentication.isAuthenticated = true;
        const roles = this.jwtHelperService.decodeToken(token).roles;
        this.authentication.subject = this.jwtHelperService.decodeToken(token).sub;

        this.authentication.userRoles = [];
        if (roles) {
          roles.forEach(role => {
            this.authentication.userRoles.push(role.authority);
          });
        }

        this.authentication.hasDefaultAdminRole = this.authentication.userRoles.includes('DEFAULT_SYSTEM_ADMINISTRATOR') ? true : false;
        this.authentication.hasAdminRole = this.authentication.userRoles.includes('SYSTEM_ADMINISTRATOR') ? true : false;
        this.authentication.hasIcaoRORole = this.authentication.userRoles.includes('REGIONAL_OFFICER') ? true : false;
        this.authentication.hasIcaoDRDRole = this.authentication.userRoles.includes('DEPUTY_REGIONAL_DIRECTOR') ? true : false;
        this.authentication.hasIcaoRDRole = this.authentication.userRoles.includes('REGIONAL_DIRECTOR') ? true : false;
        this.authentication.hasStateUserRole = this.authentication.userRoles.includes('STATE_USER') || this.authentication.userRoles.includes('STATE_DGCA') ? true : false;
        this.authentication.hasOtherUserRole = this.authentication.userRoles.includes('OTHER_USER') ? true : false;
        this.authentication.token = token;

        this.emitAuthenticationSubjctSubjct();

      }
    }
  }

  isTockenPasrsable(token: any) {
    try {
      this.jwtHelperService.decodeToken(token);
      return true;
    } catch {
      return false;
    }
  }


  checkAuthentication() {
    this.parseToken(this.loadToken());
    this.loadAuthenticatedUser();
  }

  saveToken(token: any) {
    localStorage.setItem(this.STORAGE_ITEM_TOKEN, token);
  }


  loadToken() {
    return localStorage.getItem(this.STORAGE_ITEM_TOKEN);
  }

  removeToken() {
    localStorage.removeItem(this.STORAGE_ITEM_TOKEN);
  }


  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}${route}`;
  }

  resetAuthentication() {
    this.authentication = {
      user: null,
      isAuthenticated: false,
      userRoles: [],
      hasDefaultAdminRole: false,
      hasAdminRole: false,
      hasIcaoRORole: false,
      hasIcaoDRDRole: false,
      hasIcaoRDRole: false,
      hasStateUserRole: false,
      hasOtherUserRole: false,
      token: null,
      subject: null
    };
  }


  generateAuthorizationHeader() {
    return {
      headers: new HttpHeaders({ authorization: this.authentication.token })
    };
  }
}
