<div class="p-grid p-fluid">
    <div *ngFor="let areaDeficiencies of deficienciesPerArea" class="p-col-12 p-md-4 p-lg-4">
        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
            <div class="p-d-flex p-ai-center muted-text">
                <i class="pi pi-folder purple-color"></i>
                <h6 class="p-m-0" [ngClass]="{'p-pl-2': !app.isRTL, 'p-pr-2': app.isRTL}">
                    {{areaDeficiencies.areaCode}}</h6>
            </div>
            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="p-mb-1 fs-xlarge"><b>{{areaDeficiencies.totalReportedDeficiencies}}</b></span>
                </div>
            </div>
            <div *ngIf="areaDeficiencies.totalReportedDeficiencies" class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="overview-status p-p-1 blue-bgcolor fs-small">{{areaDeficiencies.totalReportedDeficiencies}}
                        {{labels.Reported}}</span>
                </div>
                <div class="p-d-flex p-flex-column">
                    <span class="overview-status p-p-1 orange-bgcolor fs-small">{{areaDeficiencies.openedDeficiencies}}
                        {{labels.Opened}}</span>
                </div>
                <div class="p-d-flex p-flex-column">
                    <span class="overview-status p-p-1 teal-bgcolor fs-small">{{areaDeficiencies.resolvedDeficiencies}}
                        {{labels.Resolved}}</span>
                </div>
            </div>
            <div *ngIf="!areaDeficiencies.totalReportedDeficiencies" class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                <div class="p-d-flex p-flex-column">
                    <span class="overview-status p-p-1 fs-small">...</span>
                </div>
                <div class="p-d-flex p-flex-column">
                    <span class="overview-status p-p-1  fs-small">...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-4">
        <div class="card">
            <h5 class="centerText">{{labels.DistributionPerStatus}}</h5>
            <p-chart type="pie" [data]="deficienciesPerAreaPieData" [options]="chartsOptions2"></p-chart>
        </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="card">
            <h5 class="centerText">{{labels.DistributionPerStates}}</h5>
            <p-chart type="bar" [data]="deficienciesPerStates" [options]="chartsOptions"></p-chart>
        </div>
    </div>
</div>
