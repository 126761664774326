import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DocumentViewerComponent } from './view/document-viewer/document-viewer.component';
import { PrintSectionDirective } from './control/print-section.directive';
import { PrintA3Directive } from './control/print-a3.directive';
import { DocumentViewerBookComponent } from './view/document-viewer-book/document-viewer-book.component';
import { DocumentViewerPageComponent } from './view/document-viewer-page/document-viewer-page.component';
import { DocumentViewerHeaderComponent } from './view/document-viewer-header/document-viewer-header.component';
import { DocumentViewerBodyComponent } from './view/document-viewer-body/document-viewer-body.component';
import { DocumentViewerFooterComponent } from './view/document-viewer-footer/document-viewer-footer.component';
import { DocumentViewerParagraphComponent } from './view/document-viewer-paragraph/document-viewer-paragraph.component';
import { DocumentViewerTableComponent } from './view/document-viewer-table/document-viewer-table.component';
import { DocumentViewerTableHeaderComponent } from './view/document-viewer-table-header/document-viewer-table-header.component';
import { DocumentViewerTableColumnsetComponent } from './view/document-viewer-table-columnset/document-viewer-table-columnset.component';
import { DocumentViewerTableRowComponent } from './view/document-viewer-table-row/document-viewer-table-row.component';
import { DocumentViewerImageComponent } from './view/document-viewer-image/document-viewer-image.component';
import { ReportRoutingModule } from './report-routing.module';
import { DeficiencyReportViewComponent } from './view/deficiency-report-view/deficiency-report-view.component';
import { DeficiencyDetailsReportViewComponent } from './view/deficiency-details-report-view/deficiency-details-report-view.component';


@NgModule({
  declarations: [
    DocumentViewerComponent,
    PrintSectionDirective,
    PrintA3Directive,
    DocumentViewerBookComponent,
    DocumentViewerPageComponent,
    DocumentViewerHeaderComponent,
    DocumentViewerBodyComponent,
    DocumentViewerFooterComponent,
    DocumentViewerParagraphComponent,
    DocumentViewerTableComponent,
    DocumentViewerTableHeaderComponent,
    DocumentViewerTableColumnsetComponent,
    DocumentViewerTableRowComponent,
    DocumentViewerImageComponent,
    DeficiencyReportViewComponent,
    DeficiencyDetailsReportViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReportRoutingModule
  ],
  exports: [
    DocumentViewerComponent,
    PrintSectionDirective,
    PrintA3Directive
  ]
})
export class ReportModule { }
