import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LocaleService } from '../services/locale.service';
import { LocaleLanguage } from 'src/app/reporting-areas/model/locale-language';
import { UrlService } from '../services/url.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

export class Translatable {

  labels: any;
  previousUrl: string;
  preferedLanguage: LocaleLanguage;
  preferedLanguageSubscription: Subscription;

  constructor(
    private className: string,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public router: Router,
    public urlService: UrlService
  ) {
  }

  initUrl() {

  }

  // Subscribe to locale, to get upadted in the event of Prefered Language change
  subscribeToPreferedLanguage() {
    this.preferedLanguageSubscription = this.localeService.preferedLanguageSubject.subscribe(data => {
      this.preferedLanguage = data;
      this.translateLabels();
    });
    this.localeService.emitPreferedLanguage();
  }

  // Translate labels of this component
  translateLabels() {
    const labelsProperties = Object.getOwnPropertyNames(this.labels);

    const translatableProperties = [];
    labelsProperties.forEach(property => {
      translatableProperties.push(this.className + 'Labels.' + property);
    });

    this.translateService.use(this.preferedLanguage.locale);
    this.translateService.get(translatableProperties).subscribe(translated => {
      labelsProperties.forEach(element => {
        this.labels[element] = translated[this.className + 'Labels.' + element];
      });

      this.initMenuModel();
    });
  }

  initMenuModel() { }

}
