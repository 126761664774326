import { Component, OnInit, OnDestroy } from '@angular/core';
import { Organization } from '../model/organization';
import { User } from '../model/user';
import { Subscription } from 'rxjs';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-user-registered-view',
  templateUrl: './user-registered-view.component.html',
  styleUrls: ['./user-registered-view.component.scss']
})
export class UserRegisteredViewComponent extends Translatable implements OnInit, OnDestroy {


  USER_REGISTERED_UPDATE = '/system-administration/user-registered-update';
  USER_REGISTERED_VIEW = '/system-administration/user-registered-view';

  organizations: Organization[];
  users: User[];
  selectedUser: User;

  activeTab: number;
  activeRegisteredUserTabSubsciption: Subscription;

  usersData: { organization: Organization; users: User[]; }[];


  usersSubsciption: Subscription;
  organizationsSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    RegisteredUsers: 'Registered users',
    Update: 'Update',
    Delete: 'Delete',
    Search: 'Search',
    UserDetails: 'User details',
    Email: 'Email',
    Roles: 'Roles',
    Status: 'Status',
    Nodata: 'No data found !',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected user !'
  };


  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'UserRegisteredViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.systemAdministrationService.loadOrganizations();
    this.systemAdministrationService.loadUsers();
    this.subscribeToPreferedLanguage();
    this.subscribeToActiveRegisteredUserTab();
    this.subscribeToOrganizations();
    this.subscribeToUsers();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.usersSubsciption.unsubscribe();
    this.organizationsSubsciption.unsubscribe();
    this.activeRegisteredUserTabSubsciption.unsubscribe();
  }


  subscribeToOrganizations() {
    this.organizationsSubsciption = this.systemAdministrationService.organizationsSubjet.subscribe(data => {
      this.organizations = data;

      if (this.organizations && this.users) {
        this.buildUsersData();
      }
    });

    this.systemAdministrationService.emitOrganizationsSubject();
  }

  subscribeToUsers() {
    this.usersSubsciption = this.systemAdministrationService.registeredUsersSubjet.subscribe(data => {
      this.users = data;

      if (this.organizations && this.users) {
        this.buildUsersData();
      }
    });

    this.systemAdministrationService.emitRegisteredUsersSubject();
  }

  subscribeToActiveRegisteredUserTab() {
    this.activeRegisteredUserTabSubsciption = this.systemAdministrationService.activeRegisteredUserTabSubjet.subscribe(data => {
      this.activeTab = data;
    });

    this.systemAdministrationService.emitActiveRegisteredUserTabSubject();
  }



  buildUsersData() {
    if (this.organizations && this.users) {
      this.usersData = [];
      this.organizations.forEach(org => {
        const data = { organization: org, users: this.users.filter(us => us.organization.id === org.id) };
        if (data.users && data.users.length > 0) {
          this.usersData.push(data);
        }
      });
    }
  }

  avatarStyle(organization: Organization) {
    return organization.type === 'ICAORO' ? { backgroundColor: '#9c27b0', color: '#ffffff' } : { backgroundColor: '#2196F3', color: '#ffffff' };
  }

  tabStyle(organization: Organization) {
    return organization.type === 'ICAORO' ? { color: '#9c27b0' } : { color: '#2196F3' };
  }

  statusStyle(status: string) {
    return status === 'ACTIVATED' ? { color: 'green' } :
      status === 'NOT_ACTIVATED' ? { color: 'orange' } :
        { color: 'red' };
  }

  onTabOpen(e) {
    this.activeTab = e.index;
    this.systemAdministrationService.setActiveRegisteredUserTab(this.activeTab);
  }

  tabSelected(index: number) {
    if (this.activeTab === undefined || this.activeTab === null) {
      this.activeTab = index;
      return true;
    } else {
      return this.activeTab === index;
    }
  }

  upperFirstLetter(value: string) {
    value = value.replace('_', ' ').toLowerCase();
    return value[0].toUpperCase() + value.slice(1);
  }

  deleteSelectedItem() {
    if (this.selectedUser !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.systemAdministrationService.deleteUser(this.selectedUser).then(() => {
            this.systemAdministrationService.loadUsers();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedUser = null;
        },
        reject: () => {
        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedUser !== null) {
      this.systemAdministrationService.selectUser(this.selectedUser);
      this.router.navigate([this.USER_REGISTERED_UPDATE]);
    }
  }


}
