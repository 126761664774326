import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { ReportDocumentTableCell } from '../../model/report-document-table-cell';
import { ReportDocument } from '../../model/report-document';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { saveAs } from 'file-saver';


@Component({
	selector: 'app-document-viewer',
	templateUrl: './document-viewer.component.html',
	styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('printableDoc') printableDoc: ElementRef;


	cell: ReportDocumentTableCell;

	@Input() reportDocument: ReportDocument;

	imageSrcMap = new Map<string, any>();

	imageLoadRequested = new Map<string, string>();

	@Input() size = 'A4';
	@Input() layout = 'portrait';

	@Input() domTestUrl: any;

	// assetsUrl: string = 'http://localhost:4200/assets/';

	docxDocument: Document;

	menuItems: MenuItem[] = [];

	paragraphContentType = {
		PARAGRAPH_CONTENT_TEXT: 'PARAGRAPH_CONTENT_TEXT',
		PARAGRAPH_CONTENT_TABLE: 'PARAGRAPH_CONTENT_TABLE'
	};

	tableCellContentType = {
		CELL_CONTENT_TEXT: 'CELL_CONTENT_TEXT',
		CELL_CONTENT_IMAGE: 'CELL_CONTENT_IMAGE'
	};


	labels = {
		ExportWord: 'MS Word',
		ExportPdf: 'PDF',
		Print: 'Print',
		PrintTooltip: 'Print document',
		DocxFailedMessage: 'Failed to export docx !',
		PDFFailedMessage: 'Failed to export pdf !'
	};


	constructor(
		public app: AppComponent,
		public repository: RepositoryService
	) { }

	ngOnInit() {
		this.initMenu();
	}


	ngAfterViewInit(): void {

	}

	initMenu() {
		this.menuItems = [
			{ label: 'Print', title: 'Print', icon: PrimeIcons.PRINT, command: () => { this.onPrint(); } },
			{ label: 'Pdf', title: 'Save to Pdf', icon: PrimeIcons.FILE_PDF, command: () => { this.onSavePdf(); } }
		];
	}

	ngOnDestroy() {
		// this.mapParametersSubscription.unsubscribe();
	}

	onPrint() {
		window.print();
	}

	/*
	async onSaveDocx() {
		var htmlDocument = this.getFormatedXHTML();

		const route = HttpMapping.OCCURRENCE_POST_NOTIFICATION_SHEET_DOCX;

		this.repository.downloadFileAfterPost(route, htmlDocument, RepositoryService.SERVICE_AVAIRS).subscribe((data) => {
			let fileContent = new Blob([data.body], { type: data.headers.get('Content-Type') });
			let fileName = data.headers.get('File-Name');
			saveAs(fileContent, fileName);
		}, _error => {
			this.app.showError(this.labels.DocxFailedMessage);
		});

	}
	*/


	getFormatedCssXHTML() {
		const header = `
		    <?xml version="1.0" encoding="utf-8"?>
			<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">
			<html xmlns="http://www.w3.org/1999/xhtml">
			  <head>
			  <title>Deficiencies report</title>
			  <meta charset="utf-8"></meta>`
			+ this.getCSS() + `
			</head>
			<body>`;

		const footer = '</body></html>';

		const printNodeHTML = this.toNativePageHTML(document.getElementById('printableDoc')).innerHTML;

		const htmlDocument = header + printNodeHTML + footer;

		return htmlDocument;
	}

	onSavePdf() {
		const htmlDocument = this.getFormatedXHTML();

		// request pdf file
		const route = HttpMapping.DEFICIENCY_REPORT_TO_PDF.replace('{layout}', this.layout);
		this.repository.downloadFileAfterPost(route, htmlDocument).subscribe((data) => {
			const fileContent = new Blob([data.body], { type: data.headers.get('Content-Type') });
			const fileName = data.headers.get('File-Name');
			saveAs(fileContent, fileName);
		}, error => {
			// this.app.showError(this.labels.PDFFailedMessage);
		});
	}

	getFormatedXHTML() {
		const header = `
		    <?xml version="1.0" encoding="utf-8"?>
			<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">
			<html xmlns="http://www.w3.org/1999/xhtml">
			  <head>
			  <title>Deficiencies report</title>
			  <meta charset="utf-8"></meta>
			  <link rel="stylesheet" type="text/css" href="css/report-document.css">
			</head>
			<body>`;

		const footer = '</body></html>';

		const printNodeHTML = this.toNativePageHTML(document.getElementById('printableDoc')).innerHTML;

		const htmlDocument = header + printNodeHTML + footer;

		return htmlDocument;
	}

	toNativePageHTML(node: HTMLElement) {

		const printNode = document.createElement('div');
		printNode.innerHTML = node.innerHTML;

		// replace app-document-viewer-page elements by their content

		const pages = printNode.getElementsByTagName('app-document-viewer-page');

		if (pages && pages.length > 0) {
			while (pages.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = pages.item(0).innerHTML;
				pages.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
		this.toNativeHeaderHTML(printNode);
		this.toNativeParagraphHTML(printNode);
		this.toNativeTableHTML(printNode);
		this.toNativeRowHTML(printNode);
		this.toNativeColumnsetHTML(printNode);
		this.toNativeTableHeaderHTML(printNode);
		this.toNativeBodyHTML(printNode);
		this.toNativeFooterHTML(printNode);
		this.toNativeCellWidthHTML(printNode);

		return printNode;
	}

	toNativeHeaderHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-header elements by their content

		const headers = printNode.getElementsByTagName('app-document-viewer-header');

		if (headers && headers.length > 0) {
			while (headers.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = headers.item(0).innerHTML;
				headers.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}

	toNativeBodyHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-header elements by their content

		const bodys = printNode.getElementsByTagName('app-document-viewer-body');

		if (bodys && bodys.length > 0) {
			while (bodys.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = bodys.item(0).innerHTML;
				bodys.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}

	toNativeFooterHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-header elements by their content

		const footers = printNode.getElementsByTagName('app-document-viewer-footer');

		if (footers && footers.length > 0) {
			while (footers.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = footers.item(0).innerHTML;
				footers.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}


	toNativeParagraphHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const paragraphs = printNode.getElementsByTagName('app-document-viewer-paragraph');

		if (paragraphs && paragraphs.length > 0) {
			while (paragraphs.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = paragraphs.item(0).innerHTML;
				paragraphs.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}


	toNativeTableHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const tables = printNode.getElementsByTagName('app-document-viewer-table');

		if (tables && tables.length > 0) {
			while (tables.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = tables.item(0).innerHTML;
				tables.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}

	toNativeRowHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const rows = printNode.getElementsByTagName('app-document-viewer-table-row');

		if (rows && rows.length > 0) {
			while (rows.length > 0) {
				rows.item(0).remove();
			}
		}
	}

	toNativeCellWidthHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const tds = printNode.getElementsByTagName('td');

		if (tds && tds.length > 0) {
			let count = 0;
			while (count < tds.length) {

				const td = tds.item(count);
				const width = td.style.width;
				if (width) {
					td.colSpan = parseInt(width.replace('%', ''), 10);
				}
				count++;
			}
		}
	}

	toNativeColumnsetHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const columnset = printNode.getElementsByTagName('app-document-viewer-table-columnset');

		if (columnset && columnset.length > 0) {
			while (columnset.length > 0) {
				columnset.item(0).remove();
			}
		}
	}

	toNativeTableHeaderHTML(node: HTMLElement) {

		const printNode = node;

		// replace app-document-viewer-paragraph elements by their content

		const tableHeaders = printNode.getElementsByTagName('app-document-viewer-table-header');

		if (tableHeaders && tableHeaders.length > 0) {
			while (tableHeaders.length > 0) {
				const newNode = document.createElement('div');
				newNode.innerHTML = tableHeaders.item(0).innerHTML;
				tableHeaders.item(0).replaceWith(newNode);
				newNode.outerHTML = newNode.innerHTML;
			}
		}
	}


	getCSS() {
		const css = `
		<style>

		.book {
			margin: 0;
			padding: 0;
		}

		.page {
			background: white;
			display: block;
			margin-top: 0cm;
			margin-bottom: 0cm;
			margin-left: 0cm;
			margin-right: 0cm;
			box-shadow: none;
			overflow: hidden;
		}

		.page[size="A4"] {
			width: 21cm;
			height: 29.6cm;
		}

		.page[size="A4"][layout="portrait"] {
			width: 21cm;
			height: 29.6cm;
		}

		.page[size="A4"][layout="landscape"] {
			width: 29.7cm;
			height: 20.9cm;
		}

		.page-content {
			margin: 0;
			height: 100%;
			padding-right: 0.5cm;
			padding-left: 0.5cm;
			padding-top: 0.2cm;
			padding-bottom: 0.2cm;
		}

		.header {
			width: 100%;
			height: 2cm;
			padding-top: 0.5cm;
			position: relative;
			top: 0cm;
			left: 0cm;
		}

		.page-body {
			padding: 0cm;
			margin: 0cm;
		}

		.footer {
			width: 100%;
			height: 2cm;
			position: relative;
			padding-top: 0.2cm;
			padding-bottom: 0cm;
			margin: 0cm;
			bottom: 0cm;
			left: 0cm;
			vertical-align: bottom;
			text-align: center;
		}

		.paragraph {
			min-height: 15px;
			padding: 0;
			margin: 0;
			width: 100%;
		}

		.rtable {
			padding: 0;
			margin: 0;
			width: 100%;
			border: 0.2px solid black;
			border-collapse: collapse;
			display: table;
			table-layout: fixed;
		}

		.app-document-viewer-table-header {
			padding: 0;
			margin: 0;
			width: 100%;
			display: block;
		}

		.app-document-viewer-table-columnset {
			padding: 0;
			margin: 0;
			width: 100%;
			display: block;
		}

		.app-document-viewer-table-row {
			padding: 0;
			margin: 0;
			width: 100%;
			display: block;
		}

		.rtr {
			padding: 0;
			margin: 0;
			width: 100%;
			display: table;
		}

		.rtd {
			border: 0.5px solid black;
			border-collapse: collapse;
			padding: 2px;
			display: table-cell;
		}

		@page {
			margin: 0;
			padding: 0;
		}
		</style>
	`;

		return css;
	}

}
