import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLocale'
})
export class ToLocalePipe implements PipeTransform {

  transform(value: Map<string, string>, locale: string): string {
    return value !== undefined && value !== null ? value[locale] : null;
  }

}
