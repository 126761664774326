import { Injectable } from '@angular/core';
import { TechnicalArea } from './model/technical-area';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { MinReportingArea } from './model/min-reporting-area';
import { SystemAdministrationService } from '../system-administration/system-administration.service';
import { RepositoryService } from '../shared/services/repository.service';
import { HttpMapping } from '../shared/constants/http-mapping';

@Injectable({
  providedIn: 'root'
})
export class ReportingAreasService {

  /* Technical Areas */
  technicalAreas: TechnicalArea[];
  technicalAreasSubjet = new Subject<TechnicalArea[]>();

  selectedTechnicalArea: TechnicalArea;
  selectedTechnicalAreaSubjet = new Subject<TechnicalArea>();


  /* Technical Areas */
  minReportingAreas: MinReportingArea[];
  minReportingAreasSubjet = new Subject<MinReportingArea[]>();

  activeAreaTab: number;
  activeAreaTabSubjet = new Subject<number>();

  selectedMinReportingArea: MinReportingArea;
  selectedMinReportingAreaSubjet = new Subject<MinReportingArea>();

  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public repositoryService: RepositoryService
  ) {
  }

  get coreMinReportingAreas() {
    return this.minReportingAreas;
  }
  /* == Following methods handle technical Areas ===== */

  emitActiveAreaTabSubject() {
    this.activeAreaTabSubjet.next(this.activeAreaTab);
  }

  setActiveAreaTab(tab: number) {
    this.activeAreaTab = tab;
    this.emitActiveAreaTabSubject();
  }

  loadTechnicalAreas() {

    const route = HttpMapping.TECHNICAL_AREA_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.technicalAreas = data as TechnicalArea[];
        this.technicalAreas = this.technicalAreas ? this.technicalAreas.sort((a, b) => a.areaCode.localeCompare(b.areaCode)) : [];
        this.emitTechnicalAreasSubject();

        resolve();
      }, () => {
        reject();
      });
    });
  }

  emitTechnicalAreasSubject() {
    this.technicalAreasSubjet.next(this.technicalAreas);
  }

  emitSelectedTechnicalAreaSubject() {
    this.selectedTechnicalAreaSubjet.next(this.selectedTechnicalArea);
  }

  selectArea(area: TechnicalArea) {
    this.selectedTechnicalArea = area;
    this.emitSelectedTechnicalAreaSubject();
  }

  deleteArea(area: TechnicalArea): Promise<any> {
    const route = HttpMapping.TECHNICAL_AREA_DELETE_BY_ID.replace('{id}', area.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createArea(area: TechnicalArea): Promise<any> {
    const route = HttpMapping.TECHNICAL_AREA_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.create(route, area).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateArea(area: TechnicalArea): Promise<any> {
    const route = HttpMapping.TECHNICAL_AREA_PUT_BY_ID.replace('{id}', area.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.update(route, area).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });

  }

  /* == Following methods handle minimum reporting Areas ===== */

  loadMinreportingAreas() {
    const route = HttpMapping.MINREPORTING_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.minReportingAreas = data as MinReportingArea[];
        this.emitMinreportingAreasSubject();

        resolve();
      }, () => {
        reject();
      });
    });

  }

  emitMinreportingAreasSubject() {
    this.minReportingAreasSubjet.next(this.minReportingAreas);
  }

  emitSelectedMinreportingAreaSubject() {
    this.selectedMinReportingAreaSubjet.next(this.selectedMinReportingArea);
  }

  selectMinreportingArea(minReporting: MinReportingArea) {
    this.selectedMinReportingArea = minReporting;
    this.emitSelectedMinreportingAreaSubject();
  }

  deleteMinreportingArea(minReporting: MinReportingArea): Promise<any> {
    const route = HttpMapping.MINREPORTING_DELETE_BY_ID.replace('{id}', minReporting.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createMinreportingArea(minReporting: MinReportingArea): Promise<any> {
    const route = HttpMapping.MINREPORTING_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.create(route, minReporting).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateMinreportingArea(minReporting: MinReportingArea): Promise<any> {
    const route = HttpMapping.MINREPORTING_PUT_BY_ID.replace('{id}', minReporting.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.update(route, minReporting).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });

  }



  loadTechnicalAreasTest() {
    // load technical Areas
    this.technicalAreas = [
      {
        id: '1',
        areaCode: 'AGA',
        name: new Map([['en', 'Aerodrome and Ground Aids'], ['fr', 'Aerodrome et aids au sol']]),
        description: new Map([['en', 'This area includes Aerodromes design and operations, and Graound aids'],
        ['fr', 'Ce domaine comprend les opérations aérodrome et les aides au sol']]),
        responsibleExperts: this.systemAdministrationService.coreUsers.filter(usr => usr.id === 'USR1' || usr.id === 'USR3')
      },
      {
        id: '2',
        areaCode: 'ATM-SAR',
        name: new Map([['en', 'Air trafic Management, Search and Rescue'], ['fr', 'Gestion du traffic aérien, Recherche et sauvetage']]),
        description: new Map([['en', 'Air trafic Management services including Flight procedures design, Search and Rescue'],
        ['fr', 'Gestion du traffic aérien y compris la conception des procédures de vol,  Recherche et sauvetage']]),
        responsibleExperts: this.systemAdministrationService.coreUsers.filter(usr => usr.id === 'USR2')
      },
      {
        id: '3',
        areaCode: 'AIM',
        name: new Map([['en', 'Aeronautical Information Management'], ['fr', 'Gestion de l\'Information aéronautique']]),
        description: new Map([['en', 'Aeronautical Information Management services, including charts'],
        ['fr', 'Gestion de l\'Information aéronautique y compris les cartes']]),
        responsibleExperts: this.systemAdministrationService.coreUsers.filter(usr => usr.id === 'USR1')
      },
      {
        id: '4',
        areaCode: 'MET',
        name: new Map([['en', 'Aeronautical Meteorology'], ['fr', 'Météorologie aéronautique']]),
        description: new Map([['en', 'Aeronautical Meteorology services'],
        ['fr', 'Service de la Météorologie aéronautique']]),
        responsibleExperts: this.systemAdministrationService.coreUsers.filter(usr => usr.id === 'USR3')
      },
      {
        id: '5',
        areaCode: 'CNS',
        name: new Map([['en', 'Communication Navigation Surveillance'], ['fr', 'Communication Navigation Surveillance']]),
        description: new Map([['en', 'Communication Navigation Surveillance aids provision'],
        ['fr', 'Fourniture des Aides de Communication Navigation Surveillance']]),
        responsibleExperts: this.systemAdministrationService.coreUsers.filter(usr => usr.id === 'USR3' || usr.id === 'USR2')
      }
    ];
  }



  loadMinreportingAreasTest() {
    // load minReportingAreas
    this.minReportingAreas = [
      {
        id: 'AOP-1',
        technicalArea: this.technicalAreas[0],
        reference: 'Annex 14 - Vol 1, Chapter 1 PANS-Aerodromes, Part 1, 2',
        description: new Map([['en', 'Aerodrome Master Plan'],
        ['fr', 'Plan de masse d\'aérodrome']]),
        remarks: new Map([['en', 'The lack of airports master plans affect their short to medium term capacity enhancement projects; restricting their ability to fulfil capacity needs.'],
        ['fr', 'L\'absence de plans directeurs d\'aéroports affecte leurs projets de renforcement des capacités à court et moyen terme; restreignant leur capacité à répondre aux besoins de capacité']])
      },
      {
        id: 'AOP-2',
        technicalArea: this.technicalAreas[0],
        reference: 'Annex 14 - Vol 1, Chapter 2, 3 PANS-Aerodromes, Part 1, 2 AFI ANP,   Vol II - AOP',
        description: new Map([['en', 'Runways'],
        ['fr', 'Pistes']]),
        remarks: new Map([['en', 'In view of the vital function of runways in providing for safe and efficient aircraft landings and take-offs, it is imperative that their design take into account the operational and physical characteristics of the aeroplanes expected to use the runway, as well as engineering considerations.'],
        ['fr', 'Compte tenu de la fonction vitale des pistes de permettre des atterrissages et des décollages d\'aéronefs sûrs et efficaces, il est impératif que leur conception tienne compte des caractéristiques opérationnelles et physiques des avions censés utiliser la piste, ainsi que des considérations techniques.']])
      },
      {
        id: 'AOP-3',
        technicalArea: this.technicalAreas[0],
        reference: 'Annex 14 - Vol 1, Chapter 2, 3 PANS-Aerodromes, Part 1, 2',
        description: new Map([['en', 'Taxiways'],
        ['fr', 'Voies de circulation']]),
        remarks: new Map([['en', 'A properly designed taxiway system ensures a smooth, continuous flow of aircraft ground traffic, operating at the highest level of safety and efficiency and contributes to optimum aerodrome utilization'],
        ['fr', 'Un système de voies de circulation correctement conçu assure un flux régulier et continu du trafic au sol des aéronefs, fonctionnant au plus haut niveau de sécurité et d\'efficacité et contribue à une utilisation optimale de l\'aérodrome']])
      },
      {
        id: 'AOP-4',
        technicalArea: this.technicalAreas[0],
        reference: 'Annex 14 - Vol 1, Chapter 2, 3 PANS-Aerodromes, Part 1, 2 ',
        description: new Map([['en', 'Aprons'],
        ['fr', 'Airs de trafic']]),
        remarks: new Map([['en', 'Apron design should take into account safety procedures for aircraft manoeuvring and contribute to a high degree of efficiency for aircraft movements and dispensing apron services.'],
        ['fr', 'La conception de l\'aire de trafic devrait prendre en compte les procédures de sécurité pour les manœuvres des aéronefs et contribuer à un degré élevé d\'efficacité pour les mouvements d\'aéronefs et la prestation des services d\'aire de trafic.']])
      },
      {
        id: 'AOP-5',
        technicalArea: this.technicalAreas[0],
        reference: 'Annex 14 - Vol 1, Chapter 2, 5, 6, 7 PANS-Aerodromes, Part 1 AFI ANP,   Vol II - AOP',
        description: new Map([['en', 'Visual Aids'],
        ['fr', 'Aides visuelles']]),
        remarks: new Map([['en', 'Visual aids contribute to the safety and operational efficiency of aircraft and vehicle movements. Design and Good maintenance of these aids is essential to ensure that the cues that they provide are available in all circumstances.'],
        ['fr', 'Les aides visuelles contribuent à la sécurité et à l\'efficacité opérationnelle des mouvements d\'aéronefs et de véhicules. La conception et la bonne maintenance de ces aides sont essentielles pour garantir que les signaux qu\'elles fournissent sont disponibles en toutes circonstances.']])
      }
    ];
  }


}
