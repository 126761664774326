import { Component, OnInit, Input } from '@angular/core';
import { ReportDocument } from '../../model/report-document';
import { ReportDocumentPage } from '../../model/report-document-page';

@Component({
	selector: 'app-document-viewer-book',
	templateUrl: './document-viewer-book.component.html',
	styleUrls: ['./document-viewer-book.component.css']
})
export class DocumentViewerBookComponent implements OnInit {

	@Input() reportDocument: ReportDocument;
	@Input() size = 'A4';
	@Input() layout = 'portrait';

	constructor() { }

	ngOnInit() {
	}


	/* 	Handle reported remaining page
		* Insert remaining page in page array after the reporting page
		  */
	handleRemaingPageOut(event: { index: number, page: ReportDocumentPage }) {
		const pageIndex = event.index + 1;
		this.reportDocument.pages.splice(pageIndex, 0, event.page);
	}

}
