<div class="p-grid table-demo">
	<div class="p-col-12">
		<div class="card" style="min-height: 75vmin;">

			<h5>{{labels.Roles}}</h5>
			<p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                             [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
			<p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple [label]="labels.New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="onAdd()"></button>
                    <button pButton type="button" [label]="labels.Update" icon="pi pi-pencil" class="p-button-info" (click)="updateSelectedItem()" [disabled]="!selectedRole"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple [label]="labels.Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" (click)="deleteSelectedItem()" [disabled]="!selectedRole || isCoreRoleSelected"></button>
                </ng-template>
            </p-toolbar>
			
			<p-table #dtc [value]="roles" [(selection)]="selectedRole" dataKey="id" selectionMode="single"
					 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                     [rows]="10" [paginator]="true" [filterDelay]="0" 
                     [globalFilterFields]="['id','roleCode','label','description']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						<span></span>
						<span class="p-input-icon-left p-float-label">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                            <label class="global-filter-label">{{labels.Search}}</label>
                        </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
                        <th pSortableColumn="roleCode" style="background-color: lightgray;">{{labels.RoleCode}}
							<p-sortIcon field="roleCode"></p-sortIcon>
						</th>
						<th pSortableColumn="label" style="background-color: lightgray;">{{labels.Label}}
							<p-sortIcon field="label"></p-sortIcon>
						</th>
						<th pSortableColumn="description" style="background-color: lightgray;">{{labels.Description}}
							<p-sortIcon field="description"></p-sortIcon>
                        </th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-role>
					<tr [pSelectableRow]="role" style="height: 50px" [ngStyle]="coreStyle(role.roleCode)">
						<td>
							<span class="p-column-title">{{labels.RoleCode}}</span>{{role.roleCode}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Label}}</span>{{role.label}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Description}}</span>{{role.description}}
							
                        </td>						
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">{{labels.Nodata}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
    </div>
</div>