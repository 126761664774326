<div class="layout-topbar p-shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/">
            <!-- <img id="app-logo" src="assets/layout/images/logo-afi-andd-1.svg" style="height: 2.25rem"
                alt="ultima-layout"> -->
            <svg height="30" width="450">
                    <text x="20" y="20" fill="white" font-size="22px" font-weight="bold">{{applicationsService.asbuSelected ? 'AFI - ASBU' : 'AFI - ANDD'}}</text>
            </svg>
            <span class="p-mr-4">V{{aanddVersion}}</span>
        </a>
        

        <a class="layout-menu-button p-shadow-6" style="background-color: white; color: gray;"
            (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">

                <li class="layout-topbar-item">
                    <a class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle"
                        (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <img src="assets/layout/images/aandd-avatar-white.png" alt="avatar"
                            style="width: 32px; height: 32px;">
                    </a>

                    <ul class="layout-topbar-action-panel p-shadow-6" [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.activeTopbarItem === 'profile'">
                        <li class="layout-topbar-action-item" *ngFor="let language of languages">
                            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="swichLanguage(language.locale)">
                                <img src="assets/layout/images/flag-{{language.locale}}.png" alt="en"
                                    style="width: 12px; height: 12px;">
                                <span style="margin-left: 5px;">{{language.language}}</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item" *ngIf="authentication && !authentication.hasDefaultAdminRole">
                            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="onEditProfile()">
                                <i class="pi pi-id-card" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Profile</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item" *ngIf="authentication && !authentication.hasDefaultAdminRole">
                            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="onChangePassword()">
                                <i class="pi pi-unlock" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Password</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="authenticationService.logout()">
                                <i class="pi pi-power-off" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
