<div class="pages-body accessdenied-page p-d-flex p-flex-column">
    <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
        <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
            <div class="logo">
                <!-- <img src="assets/layout/images/logo-afi-andd-1.svg" alt="" /> -->
                <svg height="30" width="450">
                    <text x="20" y="20" fill="white" font-size="22px" font-weight="bold">AFI Air Navigation Deficiencies Database</text>
                </svg>
            </div>
        </div>
        <div class="topbar-right p-mr-3 p-d-flex">
        </div>
    </div>

    <p-toast position="center" key="signupMsgKey" life="10000" (onClose)="onCloseMessage()" [baseZIndex]="5000">
    </p-toast>

    <div class="p-as-center p-mt-auto p-mb-auto" *ngIf="!emailSent">
        <div class="pages-panel card p-d-flex p-flex-column" style="min-width: 500px;">
            <div class="pages-header p-px-3 p-py-1">
                <h2>REGISTRATION</h2>
            </div>

            <h4>1. Email address</h4>

            <div class="pages-detail p-mb-6 p-px-6">
                <span style="text-align: left;"> Please enter your email address to start registration
                    process</span>
            </div>
            <form [formGroup]="emailForm" (ngSubmit)="onSendEmail()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="email" type="text" pInputText formControlName="email">
                            <label for="email">Email</label>
                        </span>
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="emailConfirm" type="text" pInputText formControlName="emailConfirm">
                            <label for="emailConfirm">Confirm Email</label>
                        </span>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-4">
                        <span class="p-inputgroup-addon" style="border: 0ch;">
                            <p-button icon="pi pi-check" class="login-button p-mb-2" type="submit" label="Next"
                                [disabled]="!emailForm.valid">
                            </p-button>
                        </span>
                    </div> -->
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="back" (click)="onClose()"
                        icon="pi pi-arrow-circle-left" class="p-button-raised p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="button" label="Cancel" (click)="onClearEmail()" icon="pi pi-replay"
                        class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="Next" [disabled]="!emailForm.valid" icon="pi pi-check"
                        class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>

    <div class="p-as-center p-mt-auto p-mb-auto" *ngIf="emailSent && !emailChecked">
        <div class="pages-panel card p-d-flex p-flex-column" style="min-width: 500px;">
            <div class="pages-header p-px-3 p-py-1">
                <h2>REGISTRATION</h2>
            </div>

            <h4>2. Email verification</h4>

            <div class="pages-detail p-mb-6 p-px-6">
                <span style="color: red; text-align: left;">
                    A verification code was sent to the provided email address,<br>
                    check your email and enter the code below.
                </span>
            </div>
            <form [formGroup]="emailCheckForm" (ngSubmit)="onCheckEmail()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="email" type="text" pInputText formControlName="email">

                        </span>
                    </div>

                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="verificationCode" type="text" pInputText formControlName="verificationCode">
                            <label for="verificationCode">Verification code</label>
                        </span>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-4">
                        <span class="p-inputgroup-addon" style="border: 0ch;">
                            <p-button icon="pi pi-check" class="login-button p-mb-2" type="submit" label="Next"
                                [disabled]="!emailCheckForm.valid">
                            </p-button>
                        </span>
                    </div> -->
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="back" (click)="backToEmail()"
                        icon="pi pi-arrow-circle-left" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="Next" [disabled]="!emailCheckForm.valid"
                        icon="pi pi-fcheck" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>

    <div class="p-as-center p-mt-auto p-mb-auto" *ngIf="emailChecked && !registered">
        <div class="pages-panel card p-d-flex p-flex-column" style="min-width: 500px;">
            <div class="pages-header p-px-3 p-py-1">
                <h2>REGISTRATION</h2>
            </div>

            <h4>3. User details</h4>

            <div class="pages-detail p-mb-6 p-px-6">
                <span style="color: blue; text-align: left;">
                    Provide your details below.
                </span>
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSignup()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <div class="p-field p-col-12 p-md-4">
                            <span class="p-float-label">
                                <input id="email" type="text" pInputText formControlName="email">

                            </span>
                        </div>
                    </div>

                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="firstName" type="text" pInputText formControlName="firstName">
                            <label for="firstName">First Name</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="lastName" type="text" pInputText formControlName="lastName">
                            <label for="lastName">Last Name</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="title" type="text" pInputText formControlName="title">
                            <label for="title">Job Title</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4" style="text-align: left;">
                        <span class="p-float-label">
                            <p-dropdown id="organization" [options]="organizationsItems" formControlName="organization">
                            </p-dropdown>
                            <label for="organization">Administration/Organization</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="passwd" type="password" pInputText formControlName="passwd">
                            <label for="passwd">Password</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="passwdConfirm" type="password" pInputText formControlName="passwdConfirm">
                            <label for="passwdConfirm">Confirm password</label>
                        </span>
                    </div>
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="Cancel" (click)="onCancel()" icon="pi pi-replay"
                        class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="Sign up" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>

    <div class="p-as-center p-mt-auto p-mb-auto" *ngIf="registered">
        <div class="pages-panel card p-d-flex p-flex-column" style="min-width: 500px;">
            <div class="pages-header p-px-3 p-py-1">
                <h2>REGISTRATION</h2>
            </div>

            <h4>Registered with success</h4>

            <div class="pages-detail p-mb-6 p-px-6">
                <span style="color: green; text-align: left;">
                    Registration completed successfuly, your account need to be activated before you can log into the
                    system. <br>
                    A notification was send to the system administration for consideration in this regard.<br>
                </span>
            </div>
            <div align="right">
                <button pButton pRipple type="button" label="Close" (click)="onClose()" icon="pi pi-clock"
                    class="p-button-raised p-mr-2 p-mb-2"></button>
            </div>
        </div>
    </div>

</div>
