import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  selectedApplication: string;

  STORAGE_ITEM_SELECTED_APPLICATION = 'selected_application';
  APPLICATION_AANDD = 'AANDD';
  APPLICATION_ASBU = 'ASBU';

  constructor() {
    this.loadSelection();
  }

  selectAsbu(){
    this.selectedApplication = this.APPLICATION_ASBU;
    this.saveSelection();
  }

  selectAandd(){
    this.selectedApplication = this.APPLICATION_AANDD;
    this.saveSelection();
  }

  get aanddSelected(){
    return this.selectedApplication === this.APPLICATION_AANDD ? true : false;
  }

  get asbuSelected(){
    return this.selectedApplication === this.APPLICATION_ASBU ? true : false;
  }

  saveSelection() {
    localStorage.setItem(this.STORAGE_ITEM_SELECTED_APPLICATION, this.selectedApplication);
  }


  loadSelection() {
    const app = localStorage.getItem(this.STORAGE_ITEM_SELECTED_APPLICATION);
    this.selectedApplication = app ? app : this.APPLICATION_AANDD;
  }

}
