<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height: 75vmin;">

            <h5>{{labels.RegisteredUsers}}</h5>
            <p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text"
                rejectButtonStyleClass="p-button-text"></p-confirmDialog>

            <div class="p-col-12" *ngIf="usersData && usersData.length &gt; 0">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                        <button pButton type="button" [label]="labels.Update" icon="pi pi-pencil" class="p-button-info"
                            (click)="updateSelectedItem()" [disabled]="!selectedUser"></button>
                    </ng-template>

                    <ng-template pTemplate="right">
                        <button pButton pRipple [label]="labels.Delete" icon="pi pi-trash"
                            class="p-button-danger p-mb-2" (click)="deleteSelectedItem()"
                            [disabled]="!selectedUser"></button>
                    </ng-template>
                </p-toolbar>

                <p-accordion (onOpen)="onTabOpen($event)">
                    <p-accordionTab *ngFor="let organization of usersData; let i=index" [selected]="tabSelected(i)">
                        <ng-template pTemplate="header">
                            <span
                                [style]="tabStyle(organization?.organization)">{{organization?.organization?.name}}</span>
                        </ng-template>
                        <p-table [value]="organization.users" [(selection)]="selectedUser" dataKey="id"
                            selectionMode="single"
                            styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                            [scrollable]="true" scrollHeight="350px">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="userDetails" style="background-color: lightgray;">
                                        {{labels.Userdetails}}
                                        <p-sortIcon field="userDetails"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="email" style="background-color: lightgray;">{{labels.Email}}
                                        <p-sortIcon field="email"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="roles" style="background-color: lightgray;">{{labels.Roles}}
                                        <p-sortIcon field="roles"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="status" style="background-color: lightgray;">{{labels.Status}}
                                        <p-sortIcon field="status"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-user>
                                <tr [pSelectableRow]="user" style="height: 50px">
                                    <td>
                                        <span class="p-column-title">{{labels.Userdetails}}</span>
                                        <a class="layout-inline-menu-action p-d-flex p-dir-row p-ai-center">
                                            <p-avatar icon="pi pi-user" styleClass="p-mr-2"
                                                [style]="avatarStyle(user.organization)" shape="circle">
                                            </p-avatar>
                                            <span class="p-d-flex p-flex-column">
                                                <span class="p-text-bold">{{user.firstName +' '+ user.lastName}}</span>
                                                <small>{{user.title}}</small>
                                            </span>
                                        </a>

                                    </td>
                                    <td>
                                        <span class="p-column-title">{{labels.Email}}</span>{{user.email}}

                                    </td>
                                    <td>
                                        <span class="p-column-title">{{labels.Roles}}</span>
                                        <span class="p-d-flex p-flex-column">
                                            <li *ngFor="let role of user.roles">{{role.label}}</li>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{labels.Status}}</span><label
                                            [style]="statusStyle(user.status)">{{upperFirstLetter(user.status)}}</label>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">{{labels.Nodata}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-accordionTab>
                </p-accordion>
            </div>

            <div class="p-col-12" *ngIf="!usersData || usersData.length === 0">
                <span>{{labels.Nodata}}</span>
            </div>

        </div>
    </div>
</div>
