import { ReportDocumentParagraphText } from './report-document-paragraph-text';
import { ReportDocumentTable } from './report-document-table';

export class ReportDocumentParagraph{
    contentType: string;
    contentText: ReportDocumentParagraphText;
    contentTable: ReportDocumentTable;
    imagePath: any;
    style: Map<string, string> ;
    offsetHeight: number;
}
