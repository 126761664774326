import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsbuReportScope } from '../asbu/asbu-report/model/asbu-report-scope';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GeoShapeService {

	asbuReportScope: AsbuReportScope;
	asbuReportScopeSubjet = new Subject<AsbuReportScope>();

	constructor(private http: HttpClient) { }

	getStateShapes(icaoRegion: string) {
		return icaoRegion ? this.http.get('/assets/geo/states-' + icaoRegion + '.geo.json') : this.http.get('/assets/geo/states.geo.json');
	}

	getAllStateShapes() {
		return this.http.get('/assets/geo/states.geo.json');
	}

	getCoverageRegions() {
		return this.http.get('/assets/geo/coverage-regions.json');
	}

	loadCoverageRegions() {
		this.http.get('/assets/geo/coverage-regions.json').subscribe((data: AsbuReportScope) => {
			this.asbuReportScope = data;
			this.emitAsbuReportScopeSubjet();
		});
	}

	emitAsbuReportScopeSubjet() {
		this.asbuReportScopeSubjet.next(this.asbuReportScope);
	}
}
