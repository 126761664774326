<!-- <div #rowDiv class="rtr" [ngStyle]="tableRow.style">
    <div class="rtd" *ngFor="let cell of tableRow?.cells" [ngStyle]="cell.style">
        <p *ngFor="let cellcontent of cell.cellContents" style="margin: 0;">
            <img *ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) === null"
                src="{{'/assets/'+cellcontent.imagePath}}" height="50%"/>

            <!-- <img *ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) != null"
                [src]="loadImage(cellcontent.imagePath)" width="50%" height="50%"/> ->
            <app-document-viewer-image *ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) != null" 
                [src]="loadImage(cellcontent.imagePath)"></app-document-viewer-image>

            <span *ngIf="cellcontent.contentType === 'CELL_CONTENT_TEXT'"
                [ngStyle]="cellcontent.text?.style">{{cellcontent.text?.content}}</span>
            <span *ngIf="cellcontent.contentType === 'CELL_CONTENT_CHECKBOX'">
                <p-checkbox [(ngModel)]="cellcontent.text.value" [binary]="true"></p-checkbox>
                <p></p>
            </span>
        </p>
    </div>
</div> -->

<tr #rowDiv class="rtr" [ngStyle]="tableRow.style">
	<td class="rtd" *ngFor="let cell of tableRow?.cells" [ngStyle]="cell.style" [attr.colspan]="cell.style['colspan']"
		[attr.align]="cell.style['text-align']" [attr.width]="cell.style['width']">
		<div [style]="{'padding': 0, 'margin': 0, 'overflow': 'hidden', 'max-height': tableRow.style['height-limit']}">
			<p *ngFor="let cellcontent of cell.cellContents" style="margin: 0">
				<img *ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) === null"
					src="{{'/assets/'+cellcontent.imagePath}}" height="50%" />

				<!-- <img *ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) != null"
                [src]="loadImage(cellcontent.imagePath)" width="50%" height="50%"/> -->
				<app-document-viewer-image
					*ngIf="cellcontent.contentType === 'CELL_CONTENT_IMAGE' && loadImage(cellcontent.imagePath) != null"
					[src]="loadImage(cellcontent.imagePath)"></app-document-viewer-image>

				<span *ngIf="cellcontent.contentType === 'CELL_CONTENT_TEXT'"
					[ngStyle]="cellcontent.text?.style">{{cellcontent.text?.content}}</span>
				<span *ngIf="cellcontent.contentType === 'CELL_CONTENT_CHECKBOX'">
					<p-checkbox [(ngModel)]="cellcontent.text.value" [binary]="true"></p-checkbox>
					<p></p>
				</span>
			</p>
		</div>
	</td>
</tr>