import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { ReportingAreasService } from 'src/app/reporting-areas/reporting-areas.service';
import { SystemAdministrationService } from 'src/app/system-administration/system-administration.service';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Authentication } from 'src/app/security/model/authentication';
import { UrlService } from 'src/app/shared/services/url.service';
import { ReportService } from 'src/app/report/report.service';
import { Deficiency } from 'src/app/deficiencies/model/deficiency';
import { DeficiencyService } from 'src/app/deficiencies/deficiency.service';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-deficiency-table',
  templateUrl: './deficiency-table.component.html',
  styleUrls: ['./deficiency-table.component.scss']
})
export class DeficiencyTableComponent extends Translatable implements OnInit, OnDestroy {

  protected isProduction = ConfigService.settings.backendServers.production;

  deficiencies: Deficiency[];

  deficienciesData: { attribute: string, value: number }[];
  deficienciesSubsciption: Subscription;

  authentication: Authentication;
  authenticationSubscription: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    DeficienciesData: 'Deficiencies data',
    Attribute: 'Attribute',
    Value: 'Value',
    TotalRecords: 'Total Records',
    PendingNotifications: 'Pending Notifications',
    DeficienciesRejected: 'Deficiencies Rejected',
    DeficienciesConfirmedByRo: 'Deficiencies Confirmed by the Regional Office',
    DeficienciesConfirmedByPirg: 'Deficiencies Confirmed by APIRG',
    DeficienciesOpened: 'Deficiencies Opened',
    DeficienciesClosed: 'Deficiencies Closed',
    DeficienciesFiled: 'Deficiencies Filed',
    ActionClearDeficienciesTable: 'Reinitialize table',
    ActionClearDeficienciesTableConfirm: 'Reinitializing the deficiency table will delete all deficiencies from the database.\n' +
      'Are you sure you want to delete all deficiencies and reinitialize the table ?',
    ActionClearDeficienciesOk: 'Deficiencies table reinitialized successfully !',
    ActionClearDeficienciesFailed: 'Failed to reinitialize deficiencies table !'
  };


  constructor(
    public fb: FormBuilder,
    public reportService: ReportService,
    public deficiencyService: DeficiencyService,
    public reportingAreasService: ReportingAreasService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public authenticationService: AuthenticationService,
    public urlService: UrlService
  ) {
    super(
      'DeficiencyTableComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.deficiencyService.loadDeficiencies();
    this.subscribeAuthentication();
    this.subscribeToDeficiencies();
    this.subscribeToPreferedLanguage();
    this.initMenuModel();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.deficienciesSubsciption.unsubscribe();
    this.authenticationSubscription.unsubscribe();
  }


  subscribeAuthentication() {
    this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
      this.authentication = authentication;
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  subscribeToDeficiencies() {
    this.deficienciesSubsciption = this.deficiencyService.allDeficienciesSubjet.subscribe(data => {
      this.deficiencies = data;

      this.buildDeficienciesData();

    });

    this.deficiencyService.emitDeficienciesSubject();
  }

  buildDeficienciesData() {

    const opendStatus = [
      // RO process
      DeficiencyService.STATUS_RO_CONFIRMED,
      DeficiencyService.STATUS_RO_CAP_SUBMITED,
      DeficiencyService.STATUS_RO_CAP_REVIEWED,
      DeficiencyService.STATUS_RO_CAP_VALIDATED,
      DeficiencyService.STATUS_RO_CAP_IMPLEMENTED,
      DeficiencyService.STATUS_RO_UNRESOLVED,

      // PIRG process
      DeficiencyService.STATUS_PIRG_CONFIRMED,
      DeficiencyService.STATUS_PIRG_CAP_SUBMITED,
      DeficiencyService.STATUS_PIRG_CAP_REVIEWED,
      DeficiencyService.STATUS_PIRG_CAP_VALIDATED,
      DeficiencyService.STATUS_PIRG_CAP_IMPLEMENTED
    ];

    const closedStatus = [
      // RO process
      DeficiencyService.STATUS_RO_CLOSED,
      DeficiencyService.STATUS_PIRG_CLOSED,
      DeficiencyService.STATUS_REMOVED
    ];

    const rejectedStatus = [
      // RO process
      DeficiencyService.STATUS_RO_REJECTED,
      DeficiencyService.STATUS_PIRG_REJECTED
    ];

    this.deficienciesData = [];

    // TotalRecords
    const totalRecordsCount = this.deficiencies ? this.deficiencies.length : 0;
    this.deficienciesData.push({ attribute: this.labels.TotalRecords, value: totalRecordsCount });

    // PendingNotifications
    const pendingNotifications = this.deficiencies ? this.deficiencies.filter(def => def.status === DeficiencyService.STATUS_REPORTED) : null;
    const pendingNotificationsCount = pendingNotifications ? pendingNotifications.length : 0;
    this.deficienciesData.push({ attribute: this.labels.PendingNotifications, value: pendingNotificationsCount });

    // DeficienciesRejected
    const deficienciesRejected = this.deficiencies ? this.deficiencies.filter(def => rejectedStatus.includes(def.status)) : null;
    const deficienciesRejectedCount = deficienciesRejected ? deficienciesRejected.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesRejected, value: deficienciesRejectedCount });

    // DeficienciesConfirmedByRo
    const deficienciesConfirmedByRo = this.deficiencies ? this.deficiencies.filter(df => df.statusHistory
      && df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CONFIRMED)) : null;
    const deficienciesConfirmedByRoCount = deficienciesConfirmedByRo ? deficienciesConfirmedByRo.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesConfirmedByRo, value: deficienciesConfirmedByRoCount });

    // DeficienciesConfirmedByPirg
    const deficienciesConfirmedByPirg = this.deficiencies ? this.deficiencies.filter(df => df.statusHistory
      && df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_PIRG_CONFIRMED)) : null;
    const deficienciesConfirmedByPirgCount = deficienciesConfirmedByPirg ? deficienciesConfirmedByPirg.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesConfirmedByPirg, value: deficienciesConfirmedByPirgCount });


    // DeficienciesOpened
    const deficienciesOpened = this.deficiencies ? this.deficiencies.filter(def => opendStatus.includes(def.status)) : null;
    const deficienciesOpenedCount = deficienciesOpened ? deficienciesOpened.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesOpened, value: deficienciesOpenedCount });

    // DeficienciesClosed
    const deficienciesClosed = this.deficiencies ? this.deficiencies.filter(def => closedStatus.includes(def.status)) : null;
    const deficienciesClosedCount = deficienciesClosed ? deficienciesClosed.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesClosed, value: deficienciesClosedCount });

    // DeficienciesFiled
    const deficienciesFiled = this.deficiencies ? this.deficiencies.filter(def => def.status === DeficiencyService.STATUS_REMOVED) : null;
    const deficienciesFiledCount = deficienciesFiled ? deficienciesFiled.length : 0;
    this.deficienciesData.push({ attribute: this.labels.DeficienciesFiled, value: deficienciesFiledCount });

  }


  onReinitialize() {

    this.confirmationService.confirm({
      key: 'deleteConfirm',
      message: this.labels.ActionClearDeficienciesTableConfirm,
      accept: () => {
        this.deficiencyService.clearDeficiencies().then(() => {
          this.deficiencyService.loadDeficiencies();
          this.messageShowService.showInfo(this.labels.ActionClearDeficienciesOk);
        }, () => {
          this.messageShowService.showWarning(this.labels.ActionClearDeficienciesFailed);
        });
      }
    });
  }


  get isDefaultAdminUser() {
    return this.authentication && this.authentication.hasDefaultAdminRole;
  }


}
