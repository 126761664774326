import { Component, OnInit, OnDestroy } from '@angular/core';
import { State } from '../model/state';
import { Subscription } from 'rxjs';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { SystemAdministrationService } from '../system-administration.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-state-view',
  templateUrl: './state-view.component.html',
  styleUrls: ['./state-view.component.scss']
})
export class StateViewComponent extends Translatable implements OnInit, OnDestroy {


  STATE_CREATE = '/system-administration/state-create';
  STATE_UPDATE = '/system-administration/state-update';
  STATE_VIEW = '/system-administration/state-view';

  states: State[];
  selectedState: State;

  statesSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    States: 'States',
    New: 'New',
    Update: 'Update',
    Delete: 'Delete',
    Search: 'Search',
    ID: 'ID',
    CountryCode: 'Country code',
    Name: 'Name',
    OfficialLanguage: 'Official language',
    IcaoLanguage: 'ICAO liaison language',
    RegionalOffice: 'ICAO Regional Office',
    Nodata: 'No data found !',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected State, if it has some organizations, delete them first !'
  };


  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'StateViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.systemAdministrationService.loadStates();
    this.subscribeToStates();
    this.subscribeToPreferedLanguage();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.statesSubsciption.unsubscribe();
  }

  subscribeToStates() {
    this.statesSubsciption = this.systemAdministrationService.statesSubjet.subscribe(data => {
      this.states = data;
    });

    this.systemAdministrationService.emitStatesSubject();
  }

  deleteSelectedItem() {
    if (this.selectedState !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.systemAdministrationService.deleteState(this.selectedState).then(() => {
            this.systemAdministrationService.loadStates();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedState = null;
        },
        reject: () => {

        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedState !== null) {
      this.systemAdministrationService.selectState(this.selectedState);
      this.router.navigate([this.STATE_UPDATE]);
    }

  }

  onAdd() {
    this.router.navigate([this.STATE_CREATE]);
  }


}
