import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocaleLanguage } from 'src/app/reporting-areas/model/locale-language';
import { RepositoryService } from './repository.service';
import { HttpMapping } from '../constants/http-mapping';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  USER_PROFILE_LOCALE = 'USER_PROFILE_LOCALE';


  languages: LocaleLanguage[] = [
    {id: 'en', locale: 'en', language: 'English' },
    {id: 'fr', locale: 'fr', language: 'Français' }
  ];

  /*
  icaoLanguages: LocaleLanguage[] = [
    { locale: 'en', language: 'English' },
    { locale: 'fr', language: 'Français' },
    { locale: 'es', language: 'Español' }
  ];

  officialLanguages: LocaleLanguage[] = [
    { locale: 'en', language: 'English' },
    { locale: 'fr', language: 'Français' },
    { locale: 'es', language: 'Español' },
    { locale: 'pt', language: 'Portuguese' }
  ];
*/

  // languages: LocaleLanguage[];
  icaoLanguages: LocaleLanguage[];
  officialLanguages: LocaleLanguage[];

  preferedLanguage = {id: 'en', locale: 'en', language: 'English' };
  preferedLanguageSubject = new Subject<LocaleLanguage>();

  icaoLanguagesSubject = new Subject<LocaleLanguage[]>();
  officialLanguagesSubject = new Subject<LocaleLanguage[]>();
  languagesSubject = new Subject<LocaleLanguage[]>();


  constructor(
    public repositoryService: RepositoryService
  ) {
    this.loadLanguages();
    this.loadLocale();
  }

  loadLanguages() {
    const route = HttpMapping.LOCALE_LANGUAGE_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGet(route).subscribe(data => {

        const localeLanguages = data as LocaleLanguage[];

        this.languages = localeLanguages.filter(loc => loc.locale === 'en' || loc.locale === 'fr');
        this.icaoLanguages = localeLanguages.filter(loc => loc.locale === 'en' || loc.locale === 'fr' || loc.locale === 'es');

        this.officialLanguages = localeLanguages;
        this.emitIcaoLanguages();
        this.emitOfficialLanguages();
        this.emitLanguages();

        resolve();
      }, () => {
        reject();
      });
    });
  }

  getLanguages() {
    return this.languages;
  }

  emitPreferedLanguage() {
    this.preferedLanguageSubject.next(this.preferedLanguage);
  }

  emitIcaoLanguages() {
    this.icaoLanguagesSubject.next(this.icaoLanguages);
  }

  emitOfficialLanguages() {
    this.officialLanguagesSubject.next(this.officialLanguages);
  }

  emitLanguages() {
    this.languagesSubject.next(this.languages);
  }

  getPreferedLanguage() {
    return this.preferedLanguage;
  }

  setPreferedLanguage(language: LocaleLanguage) {
    if (language) {
      this.preferedLanguage = language;
      this.emitPreferedLanguage();
    }
  }

  setLocale(locale: string) {
    const language = this.languages.find(lg => lg.locale === locale);
    if (language) {
      this.preferedLanguage = language;
      this.emitPreferedLanguage();
      this.saveLocale();
    }
  }

  saveLocale() {
    localStorage.setItem(this.USER_PROFILE_LOCALE, this.preferedLanguage.locale);
  }

  loadLocale() {
    const loc = localStorage.getItem(this.USER_PROFILE_LOCALE);
    const language = loc ? this.languages.find(lg => lg.locale === loc) : null;
    if (language) {
      this.preferedLanguage = language;
      this.emitPreferedLanguage();
    }
  }
}
