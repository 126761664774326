import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportingAreasService } from '../reporting-areas.service';
import { TechnicalArea } from '../model/technical-area';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Authentication } from 'src/app/security/model/authentication';
import { UrlService } from 'src/app/shared/services/url.service';
import { User } from 'src/app/system-administration/model/user';

@Component({
  selector: 'app-technical-area-view',
  templateUrl: './technical-area-view.component.html',
  styleUrls: ['./technical-area-view.component.scss']
})
export class TechnicalAreaViewComponent extends Translatable implements OnInit, OnDestroy {

  TECHNICAL_AREA_CREATE = '/reporting-areas/technical-area-create';
  TECHNICAL_AREA_UPDATE = '/reporting-areas/technical-area-update';
  TECHNICAL_AREA_VIEW = '/reporting-areas/technical-area-view';

  ACCESS_DENIED = '/access';

  technicalAreas: TechnicalArea[];
  selectedTechnicalArea: TechnicalArea;

  technicalAreasSubsciption: Subscription;

  authentication: Authentication;
  authenticationSubscription: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    TechnicalAreas: 'Technical Areas',
    New: 'New',
    Update: 'Update',
    Delete: 'Delete',
    Search: 'Search',
    AreaCode: 'Area Code',
    Name: 'Name',
    Description: 'Description',
    ResponsibleExperts: 'Responsible Experts',
    Nodata: 'No data found.',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected technical area, if some minimum reporting areas are linked to it, delete them before !'
  };

  constructor(
    public reportingAreasService: ReportingAreasService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'TechnicalAreaViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.reportingAreasService.loadTechnicalAreas();
    this.subscribeAuthentication();
    this.subscribeToTechnicalAreas();
    this.subscribeToPreferedLanguage();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.technicalAreasSubsciption.unsubscribe();
    this.authenticationSubscription.unsubscribe();
  }

  subscribeAuthentication() {
    this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
      this.authentication = authentication;
      if (!this.isAuthorizedUser) {
        this.onAccessDenied();
      }
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  subscribeToTechnicalAreas() {
    this.technicalAreasSubsciption = this.reportingAreasService.technicalAreasSubjet.subscribe(data => {
      this.technicalAreas = data;
    });

    this.reportingAreasService.emitTechnicalAreasSubject();
  }

  deleteSelectedItem() {
    if (this.selectedTechnicalArea !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.reportingAreasService.deleteArea(this.selectedTechnicalArea).then(() => {
            this.reportingAreasService.loadTechnicalAreas();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedTechnicalArea = null;
        },
        reject: () => {
        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedTechnicalArea !== null) {
      this.reportingAreasService.selectArea(this.selectedTechnicalArea);
      this.router.navigate([this.TECHNICAL_AREA_UPDATE]);
    }

  }

  onAdd() {
    this.router.navigate([this.TECHNICAL_AREA_CREATE]);
  }

  get isAuthorizedUser() {
    return this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole;
  }

  onAccessDenied() {
    this.router.navigate([this.ACCESS_DENIED]);
  }


  // check authenticated user is RO DRD
  get isIcaoDRDSession() {
    return this.authentication && this.authentication.hasIcaoDRDRole;
  }

  isIcaoDRDSessionAndOrganization(organizationId: string) {
    return this.isIcaoDRDSession && this.authentication?.user?.organization?.id === organizationId;
  }

  isIcaoSessionAndOrganization(organizationId: string) {
    return this.isAuthorizedUser && this.authentication?.user?.organization?.id === organizationId;
  }

  technicalAreaOfficeExperts(area: TechnicalArea): User[]{
    return area?.responsibleExperts?.filter(sme => sme.organization?.id === this.authentication?.user?.organization?.id);
  }

  smeStyle(officerOrganizationId){
    return this.authentication?.user?.organization?.id === officerOrganizationId ? 'black' : 'lightgray';
  }

  get sessionOrganizationId(){
    return this.authentication?.user?.organization?.id;
  }

}
