import { Injectable } from '@angular/core';
import { Deficiency } from './model/deficiency';
import { Subject, Observable } from 'rxjs';
import { SystemAdministrationService } from '../system-administration/system-administration.service';
import { ReportingAreasService } from '../reporting-areas/reporting-areas.service';
import { HttpMapping } from '../shared/constants/http-mapping';
import { RepositoryService } from '../shared/services/repository.service';
import { HttpResponse } from '@angular/common/http';
import { CorrectiveAction } from './model/corrective-action';
import { DeficiencyFilter } from './model/deficiency-filter';
import { AuthenticationService } from '../security/authentication.service';
import { LocaleService } from '../shared/services/locale.service';

@Injectable({
  providedIn: 'root'
})
export class DeficiencyService {

  // notification
  static STATUS_REPORTED = 'Reported';

  // confirmation
  static CONFIRMATION_STATUS_EXPERT_CONFIRMED = 'Expert confirmed';
  static CONFIRMATION_STATUS_EXPERT_REJECTED = 'Expert rejected';
  static CONFIRMATION_STATUS_COMMITTEE_CONFIRMED = 'Committee confirmed';
  static CONFIRMATION_STATUS_COMMITTEE_REJECTED = 'Committee rejected';
  static CONFIRMATION_STATUS_PIRG_CONFIRMED = 'PIRG confirmed';
  static CONFIRMATION_STATUS_PIRG_REJECTED = 'PIRG rejected';

  static STATUS_RO_REJECTED = 'RO rejected';
  static STATUS_PIRG_REJECTED = 'PIRG rejected';

  // RO process
  static STATUS_RO_CONFIRMED = 'RO confirmed';
  static STATUS_RO_CAP_SUBMITED = 'RO CAP submited';
  static STATUS_RO_CAP_REVIEWED = 'RO CAP reviewed';
  static STATUS_RO_CAP_VALIDATED = 'RO CAP validated';
  static STATUS_RO_CAP_IMPLEMENTED = 'RO CAP implemented';
  static STATUS_RO_CLOSED = 'RO closed';
  static STATUS_RO_UNRESOLVED = 'RO unresolved';

  // PIRG process
  static STATUS_PIRG_CONFIRMED = 'PIRG confirmed';
  static STATUS_PIRG_CAP_SUBMITED = 'PIRG CAP submited';
  static STATUS_PIRG_CAP_REVIEWED = 'PIRG CAP reviewed';
  static STATUS_PIRG_CAP_VALIDATED = 'PIRG CAP validated';
  static STATUS_PIRG_CAP_IMPLEMENTED = 'PIRG CAP implemented';
  static STATUS_PIRG_CLOSED = 'PIRG closed';

  static STATUS_REMOVED = 'Removed';

  icaoRejectedDeficiencies = [
    // RO process
    DeficiencyService.STATUS_RO_REJECTED,
    DeficiencyService.STATUS_PIRG_REJECTED
  ];

  icaoPendingDeficiencies = [
    // RO process
    DeficiencyService.STATUS_REPORTED
  ];

  icaoClosedDeficiencies = [
    // RO process
    DeficiencyService.STATUS_RO_CLOSED,
    DeficiencyService.STATUS_PIRG_CLOSED
  ];

  icaoRemovedDeficiencies = [
    // RO process
    DeficiencyService.STATUS_REMOVED
  ];


  icaoShowableDeficiencies = [
    // RO process
    DeficiencyService.STATUS_RO_CONFIRMED,
    DeficiencyService.STATUS_RO_CAP_SUBMITED,
    DeficiencyService.STATUS_RO_CAP_REVIEWED,
    DeficiencyService.STATUS_RO_CAP_VALIDATED,
    DeficiencyService.STATUS_RO_CAP_IMPLEMENTED,
    DeficiencyService.STATUS_RO_UNRESOLVED,

    // PIRG process
    DeficiencyService.STATUS_PIRG_CONFIRMED,
    DeficiencyService.STATUS_PIRG_CAP_SUBMITED,
    DeficiencyService.STATUS_PIRG_CAP_REVIEWED,
    DeficiencyService.STATUS_PIRG_CAP_VALIDATED,
    DeficiencyService.STATUS_PIRG_CAP_IMPLEMENTED
  ];

  stateShowableDeficiencies = [
    DeficiencyService.STATUS_RO_CONFIRMED,
    DeficiencyService.STATUS_RO_CAP_SUBMITED,
    DeficiencyService.STATUS_RO_CAP_REVIEWED,
    DeficiencyService.STATUS_RO_CAP_VALIDATED,
    DeficiencyService.STATUS_RO_CAP_IMPLEMENTED,
    DeficiencyService.STATUS_RO_UNRESOLVED
  ];

  otherShowableDeficiencies = [

    // PIRG process
    DeficiencyService.STATUS_PIRG_CONFIRMED,
    DeficiencyService.STATUS_PIRG_CAP_SUBMITED,
    DeficiencyService.STATUS_PIRG_CAP_REVIEWED,
    DeficiencyService.STATUS_PIRG_CAP_VALIDATED,
    DeficiencyService.STATUS_PIRG_CAP_IMPLEMENTED
  ];



  /* Deficiencies */
  allDeficiencies: Deficiency[];
  allDeficienciesSubjet = new Subject<Deficiency[]>();


  deficiencies: Deficiency[];
  deficienciesSubjet = new Subject<Deficiency[]>();

  pendingDeficiencies: Deficiency[];
  pendingDeficienciesSubjet = new Subject<Deficiency[]>();

  pendingDeficienciesCount: number;
  pendingDeficienciesCountSubjet = new Subject<number>();

  selectedDeficiency: Deficiency;
  selectedDeficiencySubjet = new Subject<Deficiency>();

  selectedCorrectiveAction: CorrectiveAction;
  selectedCorrectiveActionSubjet = new Subject<CorrectiveAction>();

  pendingNotifications: Deficiency[];
  pendingNotificationsSubjet = new Subject<Deficiency[]>();

  rejectedNotifications: Deficiency[];
  rejectedNotificationsSubjet = new Subject<Deficiency[]>();

  closedDeficiencies: Deficiency[];
  closedDeficienciesSubjet = new Subject<Deficiency[]>();

  removedDeficiencies: Deficiency[];
  removedDeficienciesSubjet = new Subject<Deficiency[]>();

  confirmedDeficiencies: Deficiency[];
  confirmedDeficienciesSubjet = new Subject<Deficiency[]>();


  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public reportingAreasService: ReportingAreasService,
    public repositoryService: RepositoryService,
    public localeService: LocaleService,
    public authenticationService: AuthenticationService
  ) {
  }



  /* == Following methods handle deficiencies ===== */

  loadDeficiencies() {

    const route = HttpMapping.DEFICIENCY_GET_ALL;

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.allDeficiencies = data as Deficiency[];
        this.filterShowableDeficiencies();
        this.emitDeficienciesSubject();
        this.emitPendingNotificationsSubject();
        this.emitRejectedNotificationsSubject();
        this.emitClosedDeficienciesSubject();
        this.emitRemovedDeficienciesSubject();
        this.emitConfirmedDeficienciesSubject();
        this.emitAllDeficienciesSubject();

        resolve();
      }, () => {
        reject();
      });
    });

  }

  filterShowableDeficiencies() {
    this.deficiencies = [];
    this.pendingNotifications = [];
    if (this.authenticationService.authentication) {

      const userState = this.authenticationService.authentication.user
        && this.authenticationService.authentication.user.organization ? this.authenticationService.authentication.user.organization.state : null;

      if (this.authenticationService.authentication.hasAdminRole ||
        this.authenticationService.authentication.hasIcaoDRDRole ||
        this.authenticationService.authentication.hasIcaoRDRole ||
        this.authenticationService.authentication.hasIcaoRORole) {

        this.deficiencies = this.allDeficiencies.filter(df => this.icaoShowableDeficiencies.includes(df.status));

        this.pendingNotifications = this.allDeficiencies.filter(df => this.icaoPendingDeficiencies.includes(df.status));

        this.rejectedNotifications = this.allDeficiencies.filter(df => this.icaoRejectedDeficiencies.includes(df.status));

        this.closedDeficiencies = this.allDeficiencies.filter(df => this.icaoClosedDeficiencies.includes(df.status));

        this.removedDeficiencies = this.allDeficiencies.filter(df => this.icaoRemovedDeficiencies.includes(df.status));

        this.confirmedDeficiencies = this.allDeficiencies.filter(df => df.statusHistory && df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CONFIRMED));

      } else if (this.authenticationService.authentication.hasStateUserRole) {

        this.deficiencies = this.allDeficiencies.filter(df => (this.stateShowableDeficiencies.includes(df.status)
          && userState && df.state.id === userState.id)
          || this.otherShowableDeficiencies.includes(df.status));

        this.confirmedDeficiencies = this.allDeficiencies.filter(df => df.statusHistory
          && ((userState && df.state.id === userState.id && df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CONFIRMED))
          ) || df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_PIRG_CONFIRMED));

      } else if (this.authenticationService.authentication.hasOtherUserRole) {

        this.deficiencies = this.allDeficiencies.filter(df => this.otherShowableDeficiencies.includes(df.status));

        this.confirmedDeficiencies = this.allDeficiencies.filter(df => df.statusHistory
          && df.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_PIRG_CONFIRMED));
      }
    }
  }

  countPendingDeficiencies() {

    const route = HttpMapping.DEFICIENCY_COUNT_PENDING;

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.pendingDeficienciesCount = data as number;
        this.emitPendingDeficienciesCountSubject();

        resolve();
      }, () => {
        reject();
      });
    });

  }



  filterDeficiencies(filter: DeficiencyFilter) {

    const route = HttpMapping.DEFICIENCY_POST_FILTER;

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.create(route, filter).subscribe(data => {
        this.deficiencies = data as Deficiency[];
        this.emitDeficienciesSubject();

        resolve();
      }, () => {
        reject();
      });
    });

  }


  reloadSelectedDeficiency() {

    const route = HttpMapping.DEFICIENCY_GET_BY_ID.replace('{id}', this.selectedDeficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.selectedDeficiency = data as Deficiency;
        this.emitSelectedDeficiencySubject();
        resolve();
      }, () => {
        reject();
      });
    });

  }

  reloadCreatedDeficiency(deficiency: Deficiency) {

    const route = HttpMapping.DEFICIENCY_GET_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        const createdDeficiency = data as Deficiency;
        resolve(createdDeficiency);
      }, () => {
        reject();
      });
    });

  }

  emitAllDeficienciesSubject() {
    this.allDeficienciesSubjet.next(this.allDeficiencies);
  }

  emitDeficienciesSubject() {
    this.deficienciesSubjet.next(this.deficiencies);
  }

  emitPendingDeficienciesSubject() {
    this.pendingDeficienciesSubjet.next(this.pendingDeficiencies);
  }

  emitPendingNotificationsSubject() {
    this.pendingNotificationsSubjet.next(this.pendingNotifications);
  }

  emitRejectedNotificationsSubject() {
    this.rejectedNotificationsSubjet.next(this.rejectedNotifications);
  }

  emitClosedDeficienciesSubject() {
    this.closedDeficienciesSubjet.next(this.closedDeficiencies);
  }

  emitRemovedDeficienciesSubject() {
    this.removedDeficienciesSubjet.next(this.removedDeficiencies);
  }

  emitConfirmedDeficienciesSubject() {
    this.confirmedDeficienciesSubjet.next(this.confirmedDeficiencies);
  }

  emitSelectedDeficiencySubject() {
    this.selectedDeficiencySubjet.next(this.selectedDeficiency);
  }

  emitPendingDeficienciesCountSubject() {
    this.pendingDeficienciesCountSubjet.next(this.pendingDeficienciesCount);
  }

  selectDeficiency(deficiency: Deficiency) {
    this.selectedDeficiency = deficiency;
    this.emitSelectedDeficiencySubject();
  }

  // DEFICIENCY_CLEAR_ALL

  clearDeficiencies(): Promise<any> {
    const route = HttpMapping.DEFICIENCY_CLEAR_ALL;

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  deleteDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_DELETE_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  createDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.create(route, deficiency).subscribe(data => {
        const createdDeficiency = data as Deficiency;
        this.emitSelectedDeficiencySubject();
        resolve(createdDeficiency);
      }, () => {
        reject();
      });
    });
  }

  reportDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_POST_REPORT;

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.create(route, deficiency).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  // DEFICIENCY_PUT_EXPERT_ANALYSIS_BY_ID

  expertAnalysis(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PUT_EXPERT_ANALYSIS_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.update(route, deficiency).subscribe((data) => {
        const savedDeficiency = data as Deficiency;
        this.selectDeficiency(savedDeficiency);
        resolve();
      }, () => {
        reject();
      });
    });
  }

  // DEFICIENCY_PUT_COMMITTEE_REVIEW_BY_ID

  committeeReview(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PUT_COMMITTEE_REVIEW_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.update(route, deficiency).subscribe((data) => {
        const savedDeficiency = data as Deficiency;
        this.selectDeficiency(savedDeficiency);
        resolve();
      }, () => {
        reject();
      });
    });
  }


  // DEFICIENCY_PUT_PIRG_REVIEW_BY_ID

  pirgReview(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PUT_PIRG_REVIEW_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.update(route, deficiency).subscribe((data) => {
        const savedDeficiency = data as Deficiency;
        this.selectDeficiency(savedDeficiency);
        resolve();
      }, () => {
        reject();
      });
    });
  }

  // DEFICIENCY_COMMITTEE_CONFIRMATION_LETTER_BY_ID
  committeeConfirmationLetter(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_COMMITTEE_CONFIRMATION_LETTER_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.downloadFileAfterGet(route).subscribe((data) => {
        resolve(data);
      }, () => {
        reject();
      });
    });
  }


  // DEFICIENCY_PIRG_CONFIRMATION_LETTER_BY_ID
  pirgConfirmationLetter(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PIRG_CONFIRMATION_LETTER_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.downloadFileAfterGet(route).subscribe((data) => {
        resolve(data);
      }, () => {
        reject();
      });
    });
  }

  // DEFICIENCY_CLOSING_LETTER_BY_ID
  closingLetter(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_CLOSING_LETTER_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.downloadFileAfterGet(route).subscribe((data) => {
        resolve(data);
      }, () => {
        reject();
      });
    });
  }


  updateDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PUT_BY_ID.replace('{id}', deficiency.id);

    // remote backend service call
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.update(route, deficiency).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Reject deficiency notification
  rejectDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REJECT_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Reactivate deficiency notification
  reactivateDeficiency(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REACTIVATE_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Confirm deficiency by RO
  confirmDeficiencyByRO(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_CONFIRM_RO_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Submit RO CAP
  submitRoCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_SUBMIT_RO_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Review RO CAP
  reviewRoCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REVIEW_RO_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Validate RO CAP
  validateRoCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_VALIDATE_RO_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Implement RO CAP
  implementRoCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_IMPLEMENT_RO_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Close RO
  closeRo(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_CLOSE_RO_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Unresolved RO
  unresolvedRo(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_UNRESOLVED_RO_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  //  reject Pirg
  rejectPirg(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REJECT_PIRG_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }



  //  Confirm Pirg
  confirmPirg(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_CONFIRM_PIRG_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Submit Pirg CAP
  submitPirgCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_SUBMIT_PIRG_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  //  Review Pirg CAP
  reviewPirgCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REVIEW_PIRG_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Validate Pirg CAP
  validatePirgCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_VALIDATE_PIRG_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  //  Implement Pirg CAP
  implementPirgCap(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_IMPLEMENT_PIRG_CAP_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Close Pirg
  closePirg(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_CLOSE_PIRG_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  //  Close Pirg
  remove(deficiency: Deficiency): Promise<any> {
    const route = HttpMapping.DEFICIENCY_PROCESSING_REMOVE_BY_ID.replace('{id}', deficiency.id);

    return new Promise<void>((resolve, reject) => {
      this.repositoryService.get(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }



  downloadResource(uri: string): Observable<HttpResponse<Blob>> {
    return this.repositoryService.downloadFile(uri);
  }

  addResource(file: any, deficiencyId: string, fileDescription: string): Promise<any> {

    const route = HttpMapping.DEFICIENCY_ADD_RESOURCE_BY_ID.replace('{deficiencyId}', deficiencyId).replace('{fileDescription}', fileDescription);
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.uploadFile(route, file).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  removeResource(deficiencyId: string, fileName: string): Promise<any> {

    const route = HttpMapping.DEFICIENCY_REMOVE_RESOURCE_BY_FILE_NAME.replace('{deficiencyId}', deficiencyId).replace('{fileName}', fileName);
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  addCorrectiveAction(deficiencyId: string, action: CorrectiveAction): Promise<any> {

    const route = HttpMapping.DEFICIENCY_ADD_CORRECTIVE_ACTION_BY_ID.replace('{deficiencyId}', deficiencyId);
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.create(route, action).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  // CORRECTIVE_ACTION_PUT_BY_ID = '/correctiveActions/update/{deficiencyId}/{actionId}'

  updateCorrectiveAction(deficiencyId: string, action: CorrectiveAction): Promise<any> {

    const route = HttpMapping.CORRECTIVE_ACTION_PUT_BY_ID.replace('{deficiencyId}', deficiencyId).replace('{actionId}', action.id);
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.update(route, action).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  deleteCorrectiveAction(deficiencyId: string, actionId: string): Promise<any> {

    const route = HttpMapping.DEFICIENCY_REMOVE_CORRECTIVE_ACTION_BY_ID.replace('{deficiencyId}', deficiencyId).replace('{actionId}', actionId);
    return new Promise<void>((resolve, reject) => {
      this.repositoryService.delete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  emitSelectedCorrectiveActionSubject() {
    this.selectedCorrectiveActionSubjet.next(this.selectedCorrectiveAction);
  }

  selectCorrectiveAction(action: CorrectiveAction) {
    this.selectedCorrectiveAction = action;
    this.emitSelectedCorrectiveActionSubject();
  }

}
