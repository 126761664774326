import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ApplicationsService } from './shared/services/applications.service';
// import * as Chart from 'chart.js';
// import * as ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	topbarTheme: string = 'blue';

	menuTheme: string = 'light';

	layoutMode: string = 'light';

	menuMode: string = 'static';

	inlineMenuPosition: string = 'bottom';

	inputStyle: string = 'filled';

	ripple: boolean = true;

	isRTL: boolean = false;

	// chartJs = Chart;

	// chartLabelPlugin = ChartDataLabels;


	constructor(private primengConfig: PrimeNGConfig, public applicationsService: ApplicationsService) { }

	ngOnInit() {
		this.primengConfig.ripple = true;
		// this.chartJs.plugins.unregister(this.chartLabelPlugin);
		this.refreshTopBar();
	}

	// Custom methods added to app component to handle change of theme for AANDD and ASBU
	refreshTopBar() {
		this.topbarTheme = this.applicationsService.asbuSelected ? 'green' : 'blue';
		this.changeTheme(this.topbarTheme);
	}

	changeTheme(theme) {

		const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
		const themeHref = 'assets/theme/' + theme + '/theme-' + this.layoutMode + '.css';
		this.replaceLink(themeLink, themeHref);
	}


	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
	}

	replaceLink(linkElement, href, callback?) {
		if (this.isIE()) {
			linkElement.setAttribute('href', href);
			if (callback) {
				callback();
			}
		} else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		}
	}

}
