import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { URLObject } from '../interfaces/url-object';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  protected backendServers = ConfigService.settings.backendServers;

  constructor(public repositoryService: RepositoryService, private http: HttpClient) { }


  async translate(source: string, fromLang: string, toLang: string): Promise<any> {
    const route = this.backendServers.translationProxy;

    const { generateRequestUrl, normaliseResponse } = require('google-translate-api-browser');

    const generatedURL = ('' + generateRequestUrl(source, { from: fromLang, to: toLang }));
    const object: URLObject = { url: generatedURL };

    // remote backend service call
    return await new Promise((resolve, reject) => {
      this.http.post(route, object).subscribe(data => {
        const tranlatedText = normaliseResponse(data).text;
        resolve(tranlatedText);
      }, error => {
        reject(error);
      });
    });
  }

  async translateFromTo(source: string, fromLang: string, toLang: string): Promise<string> {

    const { generateRequestUrl, normaliseResponse } = require('google-translate-api-browser');

    /** Proxy https://translate.google.com/translate_a/
     */

    const url = ('' + generateRequestUrl(source, { from: fromLang, to: toLang }));
    const path = url; // .replace('https://translate.google.com', '');

    return new Promise((resolve, reject) => {
      this.http.get(path).subscribe(resp => {
        const tranlatedText = normaliseResponse(resp).text;
        resolve(tranlatedText);
      }, err => {
        reject(err);
      });
    });
  }

}
