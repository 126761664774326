import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportDocument } from '../../model/report-document';
import { Subscription } from 'rxjs';
import { ReportService } from '../../report.service';
import { SelectItem, PrimeIcons, MenuItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-deficiency-report-view',
  templateUrl: './deficiency-report-view.component.html',
  styleUrls: ['./deficiency-report-view.component.scss']
})
export class DeficiencyReportViewComponent extends Translatable implements OnInit, OnDestroy {

  reportSize = 'A4';
  reportLayout = 'landscape'; // portrait landscape;


  reportDocument: ReportDocument;
  reportDocumentSubscription: Subscription;

  mainForm: FormGroup;

  reportTypeItems: MenuItem[];

  labels = {
    ReportedDeficiences: 'Reported deficiences',
    ClosedDeficiences: 'Closed deficiences'
  };

  constructor(
    public fb: FormBuilder,
    public reportService: ReportService,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public router: Router,
    public urlService: UrlService
  ) {
    super(
      'DeficiencyReportViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.reportService.generateSummaryReport(ReportService.REPORT_TYPE_REPORTED_DEFICIENCIES);
    this.subscribeToPreferedLanguage();
    this.subscribeReportDocument();
    this.initMenuModel();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.reportDocumentSubscription.unsubscribe();
  }

  initMenuModel() {
    this.reportTypeItems = [
      { label: this.labels.ReportedDeficiences, icon: PrimeIcons.BELL, command: () => this.onGenerateReported() },
      { label: this.labels.ClosedDeficiences, icon: PrimeIcons.POWER_OFF, command: () => this.onGenerateClosed() }
    ];
  }

  subscribeReportDocument() {
    this.reportDocumentSubscription = this.reportService.reportDocumentSubject.subscribe(data => {
      this.reportDocument = data as ReportDocument;
    });
    this.reportService.emitReportDocumentSubject();
  }

  onGenerateReported() {
    this.reportService.generateSummaryReport(ReportService.REPORT_TYPE_REPORTED_DEFICIENCIES);
  }

  onGenerateClosed() {
    this.reportService.generateSummaryReport(ReportService.REPORT_TYPE_CLOSED_DEFICIENCIES);
  }

}
