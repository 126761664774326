import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeficiencyReportViewComponent } from './view/deficiency-report-view/deficiency-report-view.component';
import { DeficiencyDetailsReportViewComponent } from './view/deficiency-details-report-view/deficiency-details-report-view.component';

const routes: Routes = [
  { path: 'deficiency-report-view', component: DeficiencyReportViewComponent },
  { path: 'deficiency-details-report-view', component: DeficiencyDetailsReportViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
