import { Component, OnInit, OnDestroy } from '@angular/core';
import { TechnicalArea } from '../model/technical-area';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ReportingAreasService } from '../reporting-areas.service';
import { Subscription } from 'rxjs';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { LocaleLanguage } from '../model/locale-language';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { User } from 'src/app/system-administration/model/user';
import { SelectItem } from 'primeng/api';
import { SystemAdministrationService } from 'src/app/system-administration/system-administration.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Authentication } from 'src/app/security/model/authentication';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Component({
  selector: 'app-technical-area-update',
  templateUrl: './technical-area-update.component.html',
  styleUrls: ['./technical-area-update.component.scss']
})
export class TechnicalAreaUpdateComponent extends Translatable implements OnInit, OnDestroy {

  TECHNICAL_AREA_CREATE = '/reporting-areas/technical-area-create';
  TECHNICAL_AREA_UPDATE = '/reporting-areas/technical-area-update';
  TECHNICAL_AREA_VIEW = '/reporting-areas/technical-area-view';

  selectedTechnicalArea: TechnicalArea;
  technicalAreaSubsciption: Subscription;

  mainForm: FormGroup;

  languages: LocaleLanguage[];
  languagesSubsciption: Subscription;


  icaoUsers: User[];
  icaoUsersItems: SelectItem[];

  icaoUsersSubsciption: Subscription;

  authentication: Authentication;
  authenticationSubscription: Subscription;

  labels = {
    UpdateTechnicalArea: 'Update Technical area',
    AreaCode: 'Area Code',
    Details: 'Details',
    Name: 'Name',
    Description: 'Description',
    ResponsibleExperts: 'Responsible Experts',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };

  constructor(
    public fb: FormBuilder,
    public reportingAreasService: ReportingAreasService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public authenticationService: AuthenticationService,
    public urlService: UrlService,
    public translationService: TranslationService
  ) {
    super(
      'TechnicalAreaUpdateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.systemAdministrationService.loadUsers();
    this.subscribeToLanguages();
    this.subscribeToPreferedLanguage();
    this.subscribeAuthentication();
    this.initMainForm();
    this.subscribeToIcaoUsers();
    this.subscribeToSelectedTechnicalArea();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.technicalAreaSubsciption.unsubscribe();
    this.icaoUsersSubsciption.unsubscribe();
    this.languagesSubsciption.unsubscribe();
  }

  subscribeToLanguages() {
    this.languagesSubsciption = this.localeService.languagesSubject.subscribe(data => {
      this.languages = data;
    });

    this.localeService.emitLanguages();
  }

  subscribeToIcaoUsers() {
    this.icaoUsersSubsciption = this.systemAdministrationService.icaoUsersSubjet.subscribe(data => {
      this.icaoUsers = data?.filter(user => user.status === 'ACTIVATED')
      .sort((a, b) => a.organization?.id < b.organization?.id ? -1 : 1)
      .sort((a, b) => this.isIcaoDRDSessionAndOrganization(a.organization?.id) && !this.isIcaoDRDSessionAndOrganization(b.organization?.id) ? - 1 : 0);

      if (this.icaoUsers) {
        this.icaoUsersItems = [];

        this.icaoUsers.forEach(item => {
          this.icaoUsersItems.push({
            label: item.firstName + ' ' + item.lastName,
            value: item.id,
            disabled: !this.isIcaoDRDSessionAndOrganization(item.organization?.id)
          });
        });

        if (this.selectedTechnicalArea) {
          this.fillMainForm();
        }
      }

    });
    this.systemAdministrationService.emitIcaoUsersSubject();
  }

  subscribeAuthentication() {
    this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
      this.authentication = authentication;
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  subscribeToSelectedTechnicalArea() {
    this.technicalAreaSubsciption = this.reportingAreasService.selectedTechnicalAreaSubjet.subscribe(data => {
      this.selectedTechnicalArea = data;

      if (this.selectedTechnicalArea && this.icaoUsers) {
        this.fillMainForm();
      }

    });
    this.reportingAreasService.emitSelectedTechnicalAreaSubject();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      areaCode: ['', [Validators.required]], // new FormControl({ value: '', disabled: true }),
      localeDetails: this.fb.array([]),
      responsibleExperts: new FormControl({ value: '', disabled: !this.isIcaoDRDSession })
    });

    this.languages.forEach(language => {
      this.addLocaleDetails(language.locale);
    });
  }

  get localeDetails() {
    return this.mainForm.get('localeDetails') as FormArray;
  }

  addLocaleDetails(loc: string) {
    this.localeDetails.push(
      this.fb.group({
        locale: [loc],
        name: [''],
        description: ['']
      })
    );
  }

  async onTranlate() {

    // retrieve en and fr controls
    let enControl: AbstractControl;
    let frControl: AbstractControl;
    const en = 'en';
    const fr = 'fr';
    for (const control of this.localeDetails.controls) {
      const loc = control.value.locale;
      if (loc === en) {
        enControl = control;
      } else if (loc === fr) {
        frControl = control;
      }
    }

    const translatableControls = [
      { sourceControl: enControl, sourceLang: en, targetControl: frControl, targetLang: fr },
      { sourceControl: frControl, sourceLang: fr, targetControl: enControl, targetLang: en }
    ];

    const translatableProperties = [
      'name',
      'description'
    ];

    // translate English non-empty control fields

    translatableControls.forEach(translatable => {
      translatableProperties.forEach(property => {
        if (translatable?.sourceControl?.value[property] && !translatable?.targetControl?.value[property]) {
          this.translationService.translate(translatable.sourceControl.value[property], translatable.sourceLang, translatable.targetLang).then(res => {
            translatable.targetControl.patchValue({
              [property]: res
            });
          }, error => {
            console.error(error);
          });
        }
      });

    });
  }

  fillMainForm() {
    this.mainForm.patchValue({
      areaCode: this.selectedTechnicalArea.areaCode,
      responsibleExperts: this.selectedTechnicalArea.responsibleExperts.map(expt => expt.id)
    });

    for (const control of this.localeDetails.controls) {
      const loc = control.value.locale;
      control.patchValue({
        name: this.selectedTechnicalArea.name ? this.selectedTechnicalArea.name[loc] : null,
        description: this.selectedTechnicalArea.description ? this.selectedTechnicalArea.description[loc] : null
      });
    }

  }

  onCancel() {
    this.router.navigate([this.TECHNICAL_AREA_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.onTranlate().then(() => {
        this.executeSave();
      });
    }
  }

  executeSave() {
    this.selectedTechnicalArea.areaCode = this.mainForm.value.areaCode;
    this.selectedTechnicalArea.responsibleExperts = this.mainForm.value.responsibleExperts.map(id  => this.icaoUsers.find(expt  => expt.id === id));

    if (!this.selectedTechnicalArea.name) {
      this.selectedTechnicalArea.name = new Map<string, string>();
    }
    if (!this.selectedTechnicalArea.description) {
      this.selectedTechnicalArea.description = new Map<string, string>();
    }

    for (const formValue of this.localeDetails.value) {
      if (formValue.name) {
        this.selectedTechnicalArea.name[formValue.locale] = formValue.name;
        this.selectedTechnicalArea.description[formValue.locale] = formValue.description;
      }
    }

    this.reportingAreasService.updateArea(this.selectedTechnicalArea).then(() => {
      this.reportingAreasService.loadTechnicalAreas();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });
    this.router.navigate([this.TECHNICAL_AREA_VIEW]);
  }

  // check authenticated user is RO DRD
  get isIcaoDRDSession() {
    return this.authentication && this.authentication.hasIcaoDRDRole;
  }

  isIcaoDRDSessionAndOrganization(organizationId: string) {
    return this.isIcaoDRDSession && this.authentication?.user?.organization?.id === organizationId;
  }

}
