import { ReportDocumentTableHeader } from './report-document-table-header';
import { ReportDocumentTableColumns } from './report-document-table-columns';
import { ReportDocumentTableRow } from './report-document-table-row';
import { ReportDocumentTableFooter } from './report-document-table-footer';

export class ReportDocumentTable {
 header: ReportDocumentTableHeader;
 columns: ReportDocumentTableColumns;
 rows: ReportDocumentTableRow[];
 footer: ReportDocumentTableFooter;
 style: Map<string, string> ;
 offsetHeight: number;
}
