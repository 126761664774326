import { Component, OnInit, OnDestroy } from '@angular/core';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocaleLanguage } from 'src/app/reporting-areas/model/locale-language';
import { Subscription } from 'rxjs';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { Organization } from '../model/organization';
import { State } from '../model/state';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-state-create',
  templateUrl: './state-create.component.html',
  styleUrls: ['./state-create.component.scss']
})
export class StateCreateComponent extends Translatable implements OnInit, OnDestroy {


  STATE_CREATE = '/system-administration/state-create';
  STATE_UPDATE = '/system-administration/state-update';
  STATE_VIEW = '/system-administration/state-view';

  mainForm: FormGroup;

  officialLanguages: LocaleLanguage[];
  icaoLanguages: LocaleLanguage[];
  regionalOffices: Organization[];

  officialLanguagesItems: SelectItem[];
  icaoLanguagesItems: SelectItem[];
  regionalOfficesItems: SelectItem[];

  officialLanguagesSubsciption: Subscription;
  icaoLanguagesSubsciption: Subscription;
  regionalOfficesSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    AddState: 'Add State',
    ID: 'ID',
    CountryCode: 'Country code',
    Name: 'Name',
    OfficialLanguage: 'Official language',
    IcaoLanguage: 'ICAO liaison language',
    RegionalOffice: 'ICAO Regional Office',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'StateCreateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.systemAdministrationService.loadOrganizations();
    this.subscribeToRegionalOffices();
    this.subscribeToPreferedLanguage();
    this.subscribeToOfficialLanguages();
    this.subscribeToIcaoLanguages();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.regionalOfficesSubsciption.unsubscribe();
    this.officialLanguagesSubsciption.unsubscribe();
    this.icaoLanguagesSubsciption.unsubscribe();
  }

  subscribeToRegionalOffices() {
    this.regionalOfficesSubsciption = this.systemAdministrationService.regionalOfficesSubjet.subscribe(data => {
      this.regionalOffices = data;

      if (this.regionalOffices) {
        this.regionalOfficesItems = [];
        this.regionalOfficesItems.push({ label: 'Select', value: null });

        this.regionalOffices.forEach(item => {
          this.regionalOfficesItems.push({
            label: item.name,
            value: item.id
          });
        });
      }

    });

    this.systemAdministrationService.emitRegionalOfficesSubject();
  }

  subscribeToOfficialLanguages() {
    this.officialLanguagesSubsciption = this.localeService.officialLanguagesSubject.subscribe(data => {
      this.officialLanguages = data;

      if (this.officialLanguages) {
        this.officialLanguagesItems = [];
        this.officialLanguagesItems.push({ label: 'Select', value: null });

        this.officialLanguages.forEach(item => {
          this.officialLanguagesItems.push({
            label: item.language,
            value: item
          });
        });
      }

    });

    this.localeService.emitOfficialLanguages();
  }

  subscribeToIcaoLanguages() {
    this.icaoLanguagesSubsciption = this.localeService.icaoLanguagesSubject.subscribe(data => {
      this.icaoLanguages = data;

      if (this.icaoLanguages) {
        this.icaoLanguagesItems = [];
        this.icaoLanguagesItems.push({ label: 'Select', value: null });

        this.icaoLanguages.forEach(item => {
          this.icaoLanguagesItems.push({
            label: item.language,
            value: item
          });
        });
      }

    });

    this.localeService.emitIcaoLanguages();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      id: ['', []],
      countryCode: ['', [Validators.required]],
      name: ['', [Validators.required]],
      officialLanguage: ['', [Validators.required]],
      icaoLanguage: ['', [Validators.required]],
      regionalOffice: ['', [Validators.required]]
    });

  }

  onCancel() {
    this.router.navigate([this.STATE_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    const area: State = {
      id: null,
      countryCode: this.mainForm.value.countryCode,
      name: this.mainForm.value.name,
      officialLanguage: this.mainForm.value.officialLanguage,
      icaoLanguage: this.mainForm.value.icaoLanguage,
      regionalOffice: this.regionalOffices.find(office => office.id === this.mainForm.value.regionalOffice),
    };

    this.systemAdministrationService.createState(area).then(() => {
      this.systemAdministrationService.loadStates();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });

    this.router.navigate([this.STATE_VIEW]);
  }

}
