import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from '../model/user';
import { Subscription } from 'rxjs';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends Translatable implements OnInit, OnDestroy {

  mainForm: FormGroup;

  authenticatedUser: User;
  authenticatedUserSubscription: Subscription;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    UpdateProfileName: 'Update profile name',
    Email: 'Email',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public authenticationService: AuthenticationService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'UserProfileComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.subscribeToPreferedLanguage();
    this.subscribeToAuthenticatedUser();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.authenticatedUserSubscription.unsubscribe();
  }

  subscribeToAuthenticatedUser() {
    this.authenticatedUserSubscription = this.authenticationService.authenticationSubjct.subscribe(data => {
      this.authenticatedUser = data && data.user ? data.user : null;

      if (this.authenticatedUser) {
        this.fillForm();
      }
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      email: new FormControl({ value: '', disabled: true }),
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
    });

  }

  fillForm() {
    this.mainForm.patchValue({
      email: this.authenticatedUser.email,
      firstName: this.authenticatedUser.firstName,
      lastName: this.authenticatedUser.lastName
    });
  }

  onCancel() {
    this.router.navigate([this.urlService.getPreviousUrl()]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    this.authenticatedUser.firstName = this.mainForm.value.firstName;
    this.authenticatedUser.lastName = this.mainForm.value.lastName;

    this.systemAdministrationService.updateUser(this.authenticatedUser).then(() => {
      this.authenticationService.loadAuthenticatedUser();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
      this.router.navigate([this.urlService.getPreviousUrl()]);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });
  }

}
