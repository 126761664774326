import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageShowService {

  constructor(public messageService: MessageService) { }

  showSuccess(successMessage: any) {
    this.messageService.add({ key: 'msgKey', severity: 'success', summary: 'Info', detail: successMessage });
  }

  showInfo(infoMessage: any) {
    this.messageService.add({ key: 'msgKey', severity: 'info', summary: 'Info', detail: infoMessage });
  }

  showWarning(warningMessage: any) {
    this.messageService.add({ key: 'msgKey', severity: 'warn', summary: 'Warning', detail: warningMessage });
  }

  showError(errorMessage: any) {
    this.messageService.add({ key: 'msgKey', severity: 'error', summary: 'Error', detail: errorMessage });
  }

  showLoginError(errorMessage: any) {
    this.messageService.add({ key: 'loginMsgKey', severity: 'error', summary: 'Error', detail: errorMessage });
  }

  showSignupError(errorMessage: any) {
    this.messageService.add({ key: 'signupMsgKey', severity: 'error', summary: 'Error', detail: errorMessage });
  }

  showSignupSuccess(successMessage: any) {
    this.messageService.add({ key: 'signupMsgKey', severity: 'success', summary: 'Info', detail: successMessage });
  }
}
