import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ConfigService } from './config.service';
import { Authentication } from 'src/app/security/model/authentication';
import { AuthenticationService } from 'src/app/security/authentication.service';

@Injectable({
 providedIn: 'root'
})
export class RepositoryService implements OnDestroy {

	// tslint:disable: indent
	authentication: Authentication;
	authenticationSubscription: Subscription;

	protected backendServers = ConfigService.settings.backendServers;

	constructor(
		private http: HttpClient,
		public authenticationService: AuthenticationService
	) {
		this.subscribeAuthentication();
	}

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	subscribeAuthentication() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}


	uploadFile(route: string, file: any) {
		let body = new FormData();
		body.append('file', file);
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateFileHeaders());

	}

	downloadFile(route: string) {
		return this.http.get(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress),
			{
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					authorization: this.authentication.token
				}
			});

	}

	downloadFileAfterPost(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress),
			body,
			{
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					authorization: this.authentication.token
				}
			}
		);
	}

	downloadFileAfterGet(route: string) {
		return this.http.get(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress),
			{
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					authorization: this.authentication.token
				}
			}
		);
	}

	postThenLoadFile(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress),
			body,
			{
				responseType: 'blob',
				headers: { Accept: 'application/octet-stream', authorization: this.authentication.token }
			});
	}

	postDownloadFile(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress),
			body,
			{
				observe: 'response',
				responseType: 'blob',
				headers:
				{
					authorization: this.authentication.token
				}
			}
		);
	}

	createCompleteRoute(route: string, envAddress: string) {
		return `${envAddress}${route}`;
	}

	getCompleteRoute(route: string) {
		return this.createCompleteRoute(route, this.backendServers.aanddUrlAddress);
	}

	get urlBase() {
		return this.backendServers.aanddUrlAddress;
	}

	generateHeaders() {
		return {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				authorization: this.authentication.token
			})
		};
	}

	generateFileHeaders() {
		return {
			headers: new HttpHeaders({
				authorization: this.authentication.token
			})
		};
	}

	generateFileDownloadHeaders() {
		return {
			responseType: 'blob',
			headers: new HttpHeaders({
				authorization: this.authentication.token
			})
		};
	}

	generateAuthorizationHeader() {
		return {
			headers: new HttpHeaders({ authorization: this.authentication.token })
		};
	}

	send(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	create(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	update(route: string, body) {
		return this.http.put(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	delete(route: string) {
		return this.http.delete(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), this.generateAuthorizationHeader());
	}

	get(route: string) {
		return this.http.get(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), this.generateAuthorizationHeader());
	}

	securitySend(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	securityCreate(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	securityUpdate(route: string, body) {
		return this.http.put(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body, this.generateHeaders());
	}

	securityDelete(route: string) {
		return this.http.delete(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), this.generateAuthorizationHeader());
	}

	securityGet(route: string) {
		return this.http.get(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), this.generateAuthorizationHeader());
	}

	securityGetNoAuthorization(route: string) {
		return this.http.get(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress));
	}

	securityCreateNoAuthorization(route: string, body) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), body);
	}

	securityLogin(route: string, user) {
		return this.http.post(this.createCompleteRoute(route, this.backendServers.aanddUrlAddress), user, { observe: 'response' });
	}
}
