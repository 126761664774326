import { NgModule } from '@angular/core';
import { ToLocalePipe } from './pipes/to-locale.pipe';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitterModule } from 'primeng/splitter';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import {GMapModule} from 'primeng/gmap';
import {ProgressBarModule} from 'primeng/progressbar';
import { CapitalFirstPipe } from './pipes/capital-first.pipe';
import { TreeModule } from 'primeng/tree';



@NgModule({
  declarations: [
    ToLocalePipe,
    CapitalFirstPipe
  ],
  imports: [
    TableModule,
    InputTextModule,
    InputNumberModule,
    ToolbarModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    TabViewModule,
    AccordionModule,
    AvatarModule,
    MultiSelectModule,
    SplitterModule,
    CheckboxModule,
    PanelModule,
    DialogModule,
    FileUploadModule,
    CalendarModule,
    ChartModule,
    MenuModule,
    ContextMenuModule,
    TimelineModule,
    CardModule,
    RadioButtonModule,
    TieredMenuModule,
    OverlayPanelModule,
    GMapModule,
    ProgressBarModule,
    TreeModule
  ],
  exports: [
    ToLocalePipe,
    TableModule,
    InputTextModule,
    InputNumberModule,
    ToolbarModule,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    TabViewModule,
    AccordionModule,
    AvatarModule,
    MultiSelectModule,
    SplitterModule,
    CheckboxModule,
    PanelModule,
    DialogModule,
    FileUploadModule,
    CalendarModule,
    ChartModule,
    MenuModule,
    ContextMenuModule,
    TimelineModule,
    CardModule,
    RadioButtonModule,
    TieredMenuModule,
    OverlayPanelModule,
    GMapModule,
    ProgressBarModule,
    TreeModule,
    CapitalFirstPipe
  ],
  providers: [
    ToLocalePipe,
    CapitalFirstPipe
  ]
})
export class SharedModule { }
