import { ReportDocumentHeader } from './report-document-header';
import { ReportDocumentBody } from './report-document-body';
import { ReportDocumentFooter } from './report-document-footer';

export class ReportDocumentPage {
    header: ReportDocumentHeader;
    body: ReportDocumentBody;
    footer: ReportDocumentFooter;
    offsetHeight: number;
    pageNumber: number;
}
