<div class="p-grid table-demo" *ngIf="isAuthorizedUser">
    <div class="p-col-12">
        <div class="card" style="min-height: 75vmin;">

            <h5>{{labels.TechnicalAreas}}</h5>
            <p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text"
                rejectButtonStyleClass="p-button-text"></p-confirmDialog>
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple [label]="labels.New" icon="pi pi-plus"
                        class="p-button-success p-mr-2 p-mb-2" (click)="onAdd()"></button>
                    <button pButton type="button" [label]="labels.Update" icon="pi pi-pencil" class="p-button-info"
                        (click)="updateSelectedItem()" [disabled]="!selectedTechnicalArea"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple [label]="labels.Delete" icon="pi pi-trash" class="p-button-danger p-mb-2"
                        (click)="deleteSelectedItem()" [disabled]="!selectedTechnicalArea"></button>
                </ng-template>
            </p-toolbar>

            <p-table #dtc [value]="technicalAreas" [(selection)]="selectedTechnicalArea" dataKey="id"
                selectionMode="single"
                styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo" [rows]="10"
                [scrollable]="true" scrollHeight="55vmin" [globalFilterFields]="['id','name','description']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                        <span></span>
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                            <label class="global-filter-label">{{labels.Search}}</label>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="areaCode" style="background-color: lightgray;">{{labels.AreaCode}}
                            <p-sortIcon field="areaCode"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name" style="background-color: lightgray;">{{labels.Name}}
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="description" style="background-color: lightgray;">{{labels.Description}}
                            <p-sortIcon field="description"></p-sortIcon>
                        </th>
                        <th pSortableColumn="responsibleExperts" style="background-color: lightgray;">{{labels.ResponsibleExperts}}
                            <p-sortIcon field="responsibleExperts"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-technicalArea>
                    <tr [pSelectableRow]="technicalArea" style="height: 50px">
                        <td>
                            <span class="p-column-title">{{labels.AreaCode}}</span>{{technicalArea.areaCode}}

                        </td>
                        <td>
                            <span
                                class="p-column-title">{{labels.Name}}</span>{{technicalArea.name | toLocale: preferedLanguage.locale}}

                        </td>
                        <td>
                            <span
                                class="p-column-title">{{labels.Description}}</span>{{technicalArea.description | toLocale: preferedLanguage.locale}}

                        </td>
                        <td>
                            <span class="p-column-title">{{labels.ResponsibleExperts}}</span>
                            <span class="p-d-flex p-flex-column">
                                <li *ngFor="let officer of technicalArea.responsibleExperts" [style.color]="smeStyle(officer.organization?.id)">{{officer.firstName + ' '+officer.lastName}}</li>
							</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">{{labels.Nodata}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
