import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportService } from '../../report.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/shared/services/url.service';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { ReportDocument } from '../../model/report-document';
import { Subscription } from 'rxjs';
import { Deficiency } from 'src/app/deficiencies/model/deficiency';
import { DeficiencyService } from 'src/app/deficiencies/deficiency.service';

@Component({
  selector: 'app-deficiency-details-report-view',
  templateUrl: './deficiency-details-report-view.component.html',
  styleUrls: ['./deficiency-details-report-view.component.scss']
})
export class DeficiencyDetailsReportViewComponent extends Translatable implements OnInit, OnDestroy {

  reportSize = 'A4';
  reportLayout = 'portrait'; // portrait landscape;


  reportDocument: ReportDocument;
  reportDocumentSubscription: Subscription;

  labels = {
    Back: 'Go back'
  };

  constructor(
    public reportService: ReportService,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public router: Router,
    public urlService: UrlService
  ) {
    super(
      'DeficiencyDetailsReportViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.subscribeToPreferedLanguage();
    this.subscribeReportDocument();
    this.initMenuModel();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.reportDocumentSubscription.unsubscribe();
  }


  subscribeReportDocument() {
    this.reportDocumentSubscription = this.reportService.reportDocumentSubject.subscribe(data => {
      this.reportDocument = data as ReportDocument;
    });
    this.reportService.emitReportDocumentSubject();
  }

  onBack() {
    this.router.navigate([this.urlService.getPreviousUrl()]);
  }


}
