import { Component, OnInit, Input } from '@angular/core';
import { ReportDocumentTableHeader } from '../../model/report-document-table-header';

@Component({
  selector: 'app-document-viewer-table-header',
  templateUrl: './document-viewer-table-header.component.html',
  styleUrls: ['./document-viewer-table-header.component.css']
})
export class DocumentViewerTableHeaderComponent implements OnInit {

  @Input() tableHeader: ReportDocumentTableHeader;

  constructor() { }

  ngOnInit() {
  }

}
