import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../model/user';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SystemAdministrationService } from '../system-administration.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { Subscription } from 'rxjs';
import { Role } from '../model/role';
import { Organization } from '../model/organization';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-user-registered-update',
  templateUrl: './user-registered-update.component.html',
  styleUrls: ['./user-registered-update.component.scss']
})
export class UserRegisteredUpdateComponent extends Translatable implements OnInit, OnDestroy {


  USER_REGISTERED_UPDATE = '/system-administration/user-registered-update';
  USER_REGISTERED_VIEW = '/system-administration/user-registered-view';

  mainForm: FormGroup;

  organizations: Organization[];
  roles: Role[];
  statusList: { status: string, label: string }[];

  selectedUser: User;

  organizationsItems: SelectItem[];
  rolesItems: SelectItem[];
  statusListItems: SelectItem[];

  organizationsSubsciption: Subscription;
  rolesSubsciption: Subscription;
  selectedUserSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    UpdateUser: 'Update User',
    Email: 'Email',
    Password: 'Pass word',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Title: 'Title',
    Organization: 'Organization',
    Roles: 'Roles',
    Status: 'Status',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'UserRegisteredUpdateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.systemAdministrationService.loadRoles();
    this.systemAdministrationService.loadOrganizations();
    this.subscribeToPreferedLanguage();
    this.subscribeToOrganizations();
    this.subscribeToRoles();
    this.loadStatusList();
    this.subscribeToSelectedUser();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.organizationsSubsciption.unsubscribe();
    this.rolesSubsciption.unsubscribe();
  }

  subscribeToOrganizations() {
    this.organizationsSubsciption = this.systemAdministrationService.organizationsSubjet.subscribe(data => {
      this.organizations = data;

      if (this.organizations) {
        this.organizationsItems = [];
        this.organizationsItems.push({ label: 'Select', value: null });

        this.organizations.forEach(item => {
          this.organizationsItems.push({
            label: item.name,
            value: item.id
          });
        });
      }

      if (this.roles && this.selectedUser && this.organizations) {
        this.fillForm();
      }

    });

    this.systemAdministrationService.emitOrganizationsSubject();
  }

  subscribeToRoles() {
    this.rolesSubsciption = this.systemAdministrationService.rolesSubjet.subscribe(data => {
      this.roles = data;

      if (this.roles) {
        this.rolesItems = [];

        this.roles.forEach(item => {
          this.rolesItems.push({
            label: item.label,
            value: item.id
          });
        });
      }

      if (this.roles && this.selectedUser && this.organizations) {
        this.fillForm();
      }

    });

    this.systemAdministrationService.emitRolesSubject();
  }


  loadStatusList() {
    this.statusList = this.systemAdministrationService.coreUserStatus;

    if (this.statusList) {
      this.statusListItems = [];
      this.statusListItems.push({ label: 'Select', value: null });

      this.statusList.forEach(item => {
        this.statusListItems.push({
          label: item.label,
          value: item.status
        });
      });
    }

  }

  subscribeToSelectedUser() {
    this.selectedUserSubsciption = this.systemAdministrationService.selectedUserSubjet.subscribe(data => {
      this.selectedUser = data;

      if (this.roles && this.selectedUser && this.organizations) {
        this.fillForm();
      }
    });

    this.systemAdministrationService.emitSelectedUserSubject();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      title: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      roles: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

  }

  fillForm() {
    this.mainForm.patchValue({
      email: this.selectedUser.email,
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      title: this.selectedUser.title,
      organization: this.selectedUser.organization.id,
      roles: this.selectedUser.roles.map(role => role.id),
      status: this.selectedUser.status
    });
  }

  onCancel() {
    this.router.navigate([this.USER_REGISTERED_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    this.selectedUser.firstName = this.mainForm.value.firstName;
    this.selectedUser.lastName = this.mainForm.value.lastName;
    this.selectedUser.email = this.mainForm.value.email;
    this.selectedUser.title = this.mainForm.value.title;
    this.selectedUser.organization = this.organizations.find(org => org.id === this.mainForm.value.organization);
    this.selectedUser.roles = this.mainForm.value.roles.map((roleId: string) => this.roles.find(role => role.id === roleId));
    this.selectedUser.status = this.mainForm.value.status;

    this.systemAdministrationService.updateUser(this.selectedUser).then(() => {
      this.systemAdministrationService.loadUsers();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });

    this.router.navigate([this.USER_REGISTERED_VIEW]);
  }

}
