import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-document-viewer-image',
  templateUrl: './document-viewer-image.component.html',
  styleUrls: ['./document-viewer-image.component.css']
})
export class DocumentViewerImageComponent implements OnInit {


  @Input() src: any;

  constructor() { }

  ngOnInit() {
  }

}
