import { Component, OnInit, Input } from '@angular/core';
import { ReportDocumentTableColumns } from '../../model/report-document-table-columns';

@Component({
  selector: 'app-document-viewer-table-columnset',
  templateUrl: './document-viewer-table-columnset.component.html',
  styleUrls: ['./document-viewer-table-columnset.component.css']
})
export class DocumentViewerTableColumnsetComponent implements OnInit {

  @Input() tableColumns: ReportDocumentTableColumns;

  constructor() { }

  ngOnInit() {
  }

}
