<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}"
    [ngStyle]="style" [class]="styleClass">
    
    <a class="layout-inline-menu-action p-d-flex p-dir-row p-ai-center"
        [ngClass]="appMain.isHorizontal() ? 'p-p-3 p-p-lg-1 p-py-3' : 'p-p-3'" (click)="onClick($event)"
        pTooltip="Amy Elsner" [tooltipDisabled]="isTooltipDisabled">
        <img src="assets/layout/images/aandd-avatar-dark.png" alt="avatar" style="width: 32px; height: 32px;">
        <span class="p-d-flex p-flex-column" [ngClass]="{'p-ml-2': !app.isRTL, 'p-mr-2': app.isRTL}">
            <span class="p-text-bold">{{authentication?.user?.firstName}} {{authentication?.user?.lastName}}</span>
            <small>{{authentication?.user?.title}}</small>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down"
            [ngClass]="{'p-ml-auto': !app.isRTL, 'p-mr-auto': app.isRTL}"></i>
    </a>
    

    <ul class="layout-inline-menu-action-panel" 
        [@menu]="appMain.inlineMenuActive[key] ? isHorizontalActive() ? 'visible' : 'visibleAnimated' : isHorizontalActive() ? 'hidden' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item" *ngFor="let language of languages">
            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="swichLanguage(language.locale)"
                [attr.tabindex]="tabIndex">
                <img src="assets/layout/images/flag-{{language.locale}}.png" alt="en"
                    style="width: 12px; height: 12px;">
                <span style="margin-left: 5px;">{{language.language}}</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" *ngIf="authentication && !authentication.hasDefaultAdminRole">
            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="onEditProfile()">
                <i class="pi pi-id-card" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                <span>Profile</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item" *ngIf="authentication && !authentication.hasDefaultAdminRole">
            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="onChangePassword()">
                <i class="pi pi-unlock" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                <span>Password</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="p-d-flex p-flex-row p-ai-center" pRipple (click)="authenticationService.logout()"
                [attr.tabindex]="tabIndex">
                <i class="pi pi-power-off" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                <span>Logout</span>
            </a>
        </li>
    </ul>
</div>
<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.inlineMenuActive[key]}"
    [ngStyle]="style" [class]="styleClass">
    <span class="p-d-flex p-flex-column" [ngClass]="{'p-ml-2': !app.isRTL, 'p-mr-2': app.isRTL}">
        <small class="p-text-bold">{{authentication?.user?.organization?.name}}</small>
        <small *ngIf="authentication?.user?.organization?.name !== authentication?.user?.organization?.state?.name">{{authentication?.user?.organization?.state?.name}}</small>
    </span>
</div>
