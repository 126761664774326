import { Component, OnInit, Input, ElementRef, AfterViewInit, Output, ViewChild, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ReportDocumentParagraph } from '../../model/report-document-paragraph';
import { ReportDocumentTable } from '../../model/report-document-table';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-document-viewer-paragraph',
	templateUrl: './document-viewer-paragraph.component.html',
	styleUrls: ['./document-viewer-paragraph.component.css']
})
export class DocumentViewerParagraphComponent implements OnInit, AfterViewInit {

	@Input() reportParagraph: ReportDocumentParagraph;
	@Input() paragraphIndex: number;
	@Input() heightLimit: number;
	@Output() remaingParagraphOut = new EventEmitter<{ index: number, paragraph: ReportDocumentParagraph }>();
	@Output() paragraphHeightOut = new EventEmitter<{ index: number, paragraphHeight: number }>();
	paragraphHeight: number;



	@ViewChild('paragraphDiv')
	paragraphDiv: ElementRef;

	imageSrcMap = new Map<string, any>();

	imageLoadRequested = new Map<string, string>();

	// If paragraph has remaining content, create new paragraph and add remaining content
	remainingParagraph: ReportDocumentParagraph;

	paragraphContentType = {
		PARAGRAPH_CONTENT_TEXT: 'PARAGRAPH_CONTENT_TEXT',
		PARAGRAPH_CONTENT_TABLE: 'PARAGRAPH_CONTENT_TABLE'
	};

	tableCellContentType = {
		CELL_CONTENT_TEXT: 'CELL_CONTENT_TEXT',
		CELL_CONTENT_IMAGE: 'CELL_CONTENT_IMAGE'
	};

	constructor(
		private el: ElementRef,
		public app: AppComponent
		// private domSanitizer: DomSanitizer,
		// public fileLoadingService: FileLoadingService
	) { }

	ngOnInit() {
	}



	/*
	loadImage(image) {
		if (!this.imageLoadRequested.has(image)) {
			this.imageLoadRequested.set(image, image);
			this.downloadLogo(image, image);
		}
		return this.imageSrcMap.get(image);
	}

	downloadLogo(logo, key: string) {
		const route = HttpMapping.FILE_DOWNLOAD.replace('{filename}', logo);
		this.fileLoadingService.downloadFile(route, FileLoadingService.SERVICE_SECURITY).subscribe((data) => {
			this.displayLogo(data.body, key);
		}, err => {
			console.log(err);
		});
	}

	displayLogo(file, key: string) {
		if (file) {
			const reader = new FileReader();
			reader.onload = ((e) => {
				this.imageSrcMap.set(key, this.domSanitizer.bypassSecurityTrustUrl(<string>e.target['result']));
			});
			reader.readAsDataURL(file);
		}
	}
	*/

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.checkElementHeight();
		});
	}

	checkElementHeight() {
		const div = this.paragraphDiv.nativeElement as HTMLDivElement;
		this.paragraphHeight = div.offsetHeight;
		this.paragraphHeightOut.emit({ index: this.paragraphIndex, paragraphHeight: this.paragraphHeight });
	}

	/* 	Handle reported remaining table
	  *  Create remaining paragraph [remainingParagraph] and add the remaining table
	  * Report the remainingParagraph to parent page body
  	*/
	handleRemainingTable(eventTable: ReportDocumentTable) {
		if (!this.remainingParagraph) { this.remainingParagraph = new ReportDocumentParagraph(); }
		this.remainingParagraph.contentType = this.reportParagraph.contentType;
		this.remainingParagraph.offsetHeight = this.reportParagraph.offsetHeight;
		this.remainingParagraph.style = this.reportParagraph.style;
		this.remainingParagraph.contentTable = eventTable;
		this.remaingParagraphOut.emit({ index: this.paragraphIndex, paragraph: this.remainingParagraph });
	}

}
