import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TechnicalAreaViewComponent } from './technical-area-view/technical-area-view.component';
import { TableModule } from 'primeng/table';
import { TechnicalAreaCreateComponent } from './technical-area-create/technical-area-create.component';
import { TechnicalAreaUpdateComponent } from './technical-area-update/technical-area-update.component';
import { MinReportingAreaViewComponent } from './min-reporting-area-view/min-reporting-area-view.component';
import { MinReportingAreaCreateComponent } from './min-reporting-area-create/min-reporting-area-create.component';
import { MinReportingAreaUpdateComponent } from './min-reporting-area-update/min-reporting-area-update.component';

const routes: Routes = [
  { path: 'technical-area-view', component: TechnicalAreaViewComponent },
  { path: 'technical-area-create', component: TechnicalAreaCreateComponent },
  { path: 'technical-area-update', component: TechnicalAreaUpdateComponent },
  { path: 'min-reporting-area-view', component: MinReportingAreaViewComponent },
  { path: 'min-reporting-area-create', component: MinReportingAreaCreateComponent },
  { path: 'min-reporting-area-update', component: MinReportingAreaUpdateComponent }


];

@NgModule({
  imports: [
    TableModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ReportingAreasRoutingModule { }
