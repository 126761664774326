<div class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <h5>{{labels.AddTechnicalArea}}</h5>
            <div>
                <p style="height: 20px;"></p>
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSave()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="areaCode" type="text" pInputText formControlName="areaCode">
                            <label for="areaCode">{{labels.AreaCode}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label" *ngIf="isIcaoDRDSession">
                            <p-multiSelect id="responsibleExperts" [options]="icaoUsersItems"
                                formControlName="responsibleExperts">
                            </p-multiSelect>
                            <label for="responsibleExperts">{{labels.ResponsibleExperts}}</label>
                        </span>
                    </div>

                    <div class="p-col-12 p-md-12">
                        <h5>{{labels.Details}}</h5>
                        <div formArrayName="localeDetails">
                            <p-tabView orientation="left" (onChange)="onTranlate()">
                                <p-tabPanel [header]="language.language"
                                    *ngFor="let language of languages; let i = index" [selected]="i == 0">

                                    <div [formGroup]="localeDetails.controls[i]">
                                        <!-- The repeated alias template -->
                                        <div class="p-field p-col-12 p-md-12">
                                            <span class="p-float-label">
                                                <input id="fieldName" type="text" pInputText formControlName="name">
                                                <label
                                                    for="fieldName">{{language.locale === 'es' ? 'Apellido' : language.locale === 'fr' ? 'Nom' : 'Name'}}</label>
                                            </span>
                                        </div>
                                        <div class="p-field p-col-12 p-md-12">
                                            <span class="p-float-label">
                                                <textarea id="fieldDescription" type="text" rows="4" pInputTextarea
                                                    formControlName="description"></textarea>
                                                <label
                                                    for="fieldDescription">{{language.locale === 'es' ? 'Descripción' : 'Description'}}</label>
                                            </span>
                                        </div>
                                    </div>

                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </div>
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="{{labels.Cancel}}" (click)="onCancel()"
                        icon="pi pi-times" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="{{labels.Save}}" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>
</div>
