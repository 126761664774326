import { Component, ElementRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { LocaleService } from './shared/services/locale.service';
import { AuthenticationService } from './security/authentication.service';
import { Subscription } from 'rxjs';
import { LocaleLanguage } from './reporting-areas/model/locale-language';
import { Router } from '@angular/router';
import { Authentication } from './security/model/authentication';
import { ApplicationsService } from './shared/services/applications.service';
import { ConfigService } from './shared/services/config.service';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    USER_PWD = '/system-administration/user-pwd';
    USER_PROFILE = '/system-administration/user-profile';
    
    aanddVersion = ConfigService.settings.aanddVersion;

    authentication: Authentication;
    authenticationSubscription: Subscription;

    languages: LocaleLanguage[];
    languagesSubsciption: Subscription;

    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        public router: Router,
        public localeService: LocaleService,
        public authenticationService: AuthenticationService,
        public applicationsService: ApplicationsService
    ) {
    }

    activeItem: number;


    @ViewChild('searchInput') searchInputViewChild: ElementRef;


    ngOnInit(): void {
        this.subscribeAuthentication();
        this.subscribeToLanguages();
    }

    ngOnDestroy(): void {
        this.languagesSubsciption.unsubscribe();
        this.authenticationSubscription.unsubscribe();
    }

    subscribeAuthentication() {
        this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
            this.authentication = authentication;
        });
        this.authenticationService.emitAuthenticationSubjctSubjct();
    }

    subscribeToLanguages() {
        this.languagesSubsciption = this.localeService.languagesSubject.subscribe(data => {
            this.languages = data;
        });

        this.localeService.emitLanguages();
    }

    onSearchAnimationEnd(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }

    swichLanguage(locale: string) {
        this.localeService.setLocale(locale);
    }

    onChangePassword(){
        this.router.navigate([this.USER_PWD]);
    }

    onEditProfile(){
        this.router.navigate([this.USER_PROFILE]);
    }
}
