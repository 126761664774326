<div class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <h5>{{labels.UpdateRole}}</h5>
            <div>
                <p style="height: 20px;"></p> 
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSave()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="roleCode" type="text" pInputText formControlName="roleCode">
                            <label for="roleCode">{{labels.RoleCode}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="label" type="text" pInputText formControlName="label">
                            <label for="label">{{labels.Label}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
                        <span class="p-float-label">
                            <textarea id="description" type="text" rows="4" pInputTextarea
                                formControlName="description"></textarea>
                            <label for="description">{{labels.Description}}</label>
                        </span>
                    </div>
                                        
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="{{labels.Cancel}}" (click)="onCancel()"
                        icon="pi pi-times" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="{{labels.Save}}" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>
</div>
