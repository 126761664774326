import { Component, OnInit, OnDestroy } from '@angular/core';
import { MinReportingArea } from '../model/min-reporting-area';
import { Subscription } from 'rxjs';
import { ReportingAreasService } from '../reporting-areas.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { TechnicalArea } from '../model/technical-area';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Authentication } from 'src/app/security/model/authentication';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-min-reporting-area-view',
  templateUrl: './min-reporting-area-view.component.html',
  styleUrls: ['./min-reporting-area-view.component.scss']
})
export class MinReportingAreaViewComponent extends Translatable implements OnInit, OnDestroy {

  MIN_REPORTING_AREA_CREATE = '/reporting-areas/min-reporting-area-create';
  MIN_REPORTING_AREA_UPDATE = '/reporting-areas/min-reporting-area-update';
  MIN_REPORTING_AREA_VIEW = '/reporting-areas/min-reporting-area-view';

  DEFICIENCY_CREATE = '/deficiencies/deficiency-create';

  minReportingAreas: MinReportingArea[];
  selectedMinReportingArea: MinReportingArea;
  technicalAreas: TechnicalArea[];

  technicalAreasSubsciption: Subscription;

  minReportingAreasSubsciption: Subscription;

  authentication: Authentication;
  authenticationSubscription: Subscription;

  activeTab: number;
  activeAreaTabSubsciption: Subscription;

  minReportingAreasData: { technicalArea: TechnicalArea; minReportingAreas: MinReportingArea[]; }[];

  contextMenuItems: MenuItem[];


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    MinimumReportingAreas: 'Minimum Reporting Areas',
    New: 'New',
    Update: 'Update',
    Delete: 'Delete',
    Report: 'Report',
    AddReportingArea: 'Add Reporting area',
    UpdateReportingArea: 'Update Reporting area',
    DeleteReportingArea: 'Delete Reporting area',
    ReportDeficiency: 'Report deficiency',
    Search: 'Search',
    ID: 'ID',
    Area: 'Area',
    Reference: 'Reference',
    Description: 'Description',
    Remarks: 'Remarks',
    Nodata: 'No data found !',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected area !'
  };

  constructor(
    public reportingAreasService: ReportingAreasService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public authenticationService: AuthenticationService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'MinReportingAreaViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.reportingAreasService.loadTechnicalAreas();
    this.reportingAreasService.loadMinreportingAreas();
    this.subscribeAuthentication();
    this.subscribeToActiveAreaTab();
    this.subscribeToTechnicalAreas();
    this.subscribeToMinReportingAreas();
    this.subscribeToPreferedLanguage();
    this.initMenuModel();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.technicalAreasSubsciption.unsubscribe();
    this.minReportingAreasSubsciption.unsubscribe();
    this.authenticationSubscription.unsubscribe();
    this.activeAreaTabSubsciption.unsubscribe();
  }

  initMenuModel() {
    this.contextMenuItems = [
      { label: this.labels.AddReportingArea, icon: 'pi pi-fw pi-plus', visible: this.isEditingUser, command: () => this.onAdd() },
      { label: this.labels.UpdateReportingArea, icon: 'pi pi-fw pi-pencil', visible: this.isEditingUser, command: () => this.updateSelectedItem() },
      { label: this.labels.DeleteReportingArea, icon: 'pi pi-fw pi-trash', visible: this.isEditingUser, command: () => this.deleteSelectedItem() },
      { label: this.labels.ReportDeficiency, icon: 'pi pi-fw pi-calendar-minus', command: () => this.onReportDeficiency() }
    ];
  }

  subscribeToActiveAreaTab() {
    this.activeAreaTabSubsciption = this.reportingAreasService.activeAreaTabSubjet.subscribe(data => {
      this.activeTab = data;
    });

    this.reportingAreasService.emitActiveAreaTabSubject();
  }

  subscribeAuthentication() {
    this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
      this.authentication = authentication;
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  subscribeToTechnicalAreas() {
    this.technicalAreasSubsciption = this.reportingAreasService.technicalAreasSubjet.subscribe(data => {
      this.technicalAreas = data;
      this.buildMinReportingAreasData();
    });

    this.reportingAreasService.emitTechnicalAreasSubject();
  }

  subscribeToMinReportingAreas() {
    this.minReportingAreasSubsciption = this.reportingAreasService.minReportingAreasSubjet.subscribe(data => {
      this.minReportingAreas = data;

      this.buildMinReportingAreasData();
    });

    this.reportingAreasService.emitMinreportingAreasSubject();
  }

  buildMinReportingAreasData() {
    if (this.technicalAreas && this.minReportingAreas) {
      this.minReportingAreasData = [];
      this.technicalAreas.forEach(area => {
        const data = { technicalArea: area, minReportingAreas: this.minReportingAreas.filter(min => min.technicalArea.id === area.id) };
        if (data.minReportingAreas && data.minReportingAreas.length > 0) {
          this.minReportingAreasData.push(data);
        }
      });
    }
  }

  onTabOpen(e) {
    this.activeTab = e.index;
    this.reportingAreasService.setActiveAreaTab(this.activeTab);
  }

  tabSelected(index: number) {
    if (this.activeTab === undefined || this.activeTab === null) {
      this.activeTab = index;
      return true;
    } else {
      return this.activeTab === index;
    }
  }

  deleteSelectedItem() {
    if (this.selectedMinReportingArea !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.reportingAreasService.deleteMinreportingArea(this.selectedMinReportingArea).then(() => {
            this.reportingAreasService.loadMinreportingAreas();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedMinReportingArea = null;
        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedMinReportingArea !== null) {
      this.reportingAreasService.selectMinreportingArea(this.selectedMinReportingArea);
      this.router.navigate([this.MIN_REPORTING_AREA_UPDATE]);
    }

  }

  onAdd() {
    this.router.navigate([this.MIN_REPORTING_AREA_CREATE]);
  }


  onReportDeficiency() {
    if (this.selectedMinReportingArea !== null) {
      this.reportingAreasService.selectMinreportingArea(this.selectedMinReportingArea);
      this.router.navigate([this.DEFICIENCY_CREATE]);
    }
  }

  get isEditingUser() {
    return this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole;
  }

}
