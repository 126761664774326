
<div class="p-grid">
	<div class="p-col-12 p-md-6">
		<div class="card">
			<h5>Vertical</h5>
			<div class="p-fluid">
				<div class="p-field">
					<label for="firstname1">Name</label>
					<input id="firstname1" type="text" pInputText>
				</div>
				<div class="p-field">
					<label for="lastname1">Email</label>
					<input id="lastname1" type="text" pInputText>
				</div>
				<div class="p-field">
					<label for="age1">Age</label>
					<input id="age1" type="text" pInputText>
				</div>
			</div>
		</div>

		<div class="card">
			<h5>Vertical Grid</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col">
					<label for="firstname2">Name</label>
					<input id="firstname2" type="text" pInputText>
				</div>
				<div class="p-field p-col">
					<label for="lastname2">Email</label>
					<input id="lastname2" type="text" pInputText>
				</div>
			</div>
		</div>
	</div>

	<div class="p-col-12 p-md-6">
		<div class="card">
			<h5>Horizontal</h5>
			<div class="p-fluid">
				<div class="p-field p-grid">
					<label for="firstname4" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name</label>
					<div class="p-col-12 p-md-10">
						<input id="firstname4" type="text" pInputText>
					</div>
				</div>
				<div class="p-field p-grid">
					<label for="lastname4" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Email</label>
					<div class="p-col-12 p-md-10">
						<input id="lastname4" type="text" pInputText>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<h5>Inline</h5>
			<div class="p-formgroup-inline">
				<div class="p-field">
                    <span class="p-float-label">
					    <input id="firstname5" type="text" pInputText>
                        <label for="firstname5">Firstname</label>
                    </span>
				</div>
				<div class="p-field">
                    <span class="p-float-label">
                        <input id="lastname5" type="text" pInputText>
                        <label for="lastname5">Lastname</label>
                    </span>
				</div>
				<button pButton pRipple type="button" label="Submit"></button>
			</div>
		</div>

		<div class="card">
			<h5>Help Text</h5>
			<div class="p-field p-fluid">
                <span class="p-float-label">
                    <input id="username" type="username" pInputText aria-describedby="username-help">
                    <label for="username">Username</label>
                </span>
				<small id="username-help">Enter your username to reset your password.</small>
            </div>
		</div>
	</div>

	<div class="p-col-12">
		<div class="card">
			<h5>Advanced</h5>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6">
					<label for="firstname6">Firstname</label>
					<input id="firstname6" type="text" pInputText>
				</div>
				<div class="p-field p-col-12 p-md-6">
					<label for="lastname6">Lastname</label>
					<input id="lastname6" type="text" pInputText>
				</div>
				<div class="p-field p-col-12">
					<label for="address">Address</label>
					<textarea id="address" type="text" rows="4" pInputTextarea></textarea>
				</div>
				<div class="p-field p-col-12 p-md-6">
					<label for="city">City</label>
					<input id="city" type="text" pInputText>
				</div>
				<div class="p-field p-col-12 p-md-3">
                    <label for="state">State</label>
                    <span class="p-float-label">
                        <p-dropdown inputId="state" [options]="states" [(ngModel)]="selectedState" optionLabel="name" [autoDisplayFirst]="false"></p-dropdown>
                        <label>Choose</label>
                    </span>
				</div>
				<div class="p-field p-col-12 p-md-3">
					<label for="zip">Zip</label>
					<input id="zip" type="text" pInputText>
				</div>
			</div>
		</div>
	</div>
</div>
