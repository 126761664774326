import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { State } from '../model/state';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Organization } from '../model/organization';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
	selector: 'app-organization-create',
	templateUrl: './organization-create.component.html',
	styleUrls: ['./organization-create.component.scss']
})
export class OrganizationCreateComponent extends Translatable implements OnInit, OnDestroy {


	ORGANIZATION_CREATE = '/system-administration/organization-create';
	ORGANIZATION_UPDATE = '/system-administration/organization-update';
	ORGANIZATION_VIEW = '/system-administration/organization-view';

	mainForm: FormGroup;

	showStates: boolean;
	isRegionalOffice: boolean;

	states: State[];

	statesItems: SelectItem[];

	organizationTypes: SelectItem[];



	statesSubsciption: Subscription;

	// labels of this component to be translated,
	// translation resources to be setted in the files /assets/i18n/{locale}.json
	labels = {
		AddOrganization: 'Add Organization',
		Name: 'Name',
		ShortName: 'Short name',
		PIRGRegion: 'PIRG Region',
		Type: 'Type',
		State: 'State',
		Address: 'Address',
		Email: 'Email',
		Website: 'Web site',
		Phone: 'Phone',
		Fax: 'Fax',
		Cancel: 'Cancel',
		Save: 'Save',
		SaveSuccesMessage: 'Saved with success !',
		SaveFailedMessage: 'Failed to save !',
		OrganizationTypeICAORO: 'ICAO Regional Office',
		OrganizationTypeStateCAA: 'State Civil Aviation Authority',
		OrganizationTypeStateANSP: 'State Air navigation service provider',
		OrganizationTypeStateAOP: 'State Aerodrome operator',
		OrganizationTypeInternationalOrg: 'International organization',
		OrganizationTypeUsersOrg: 'Users organization',
		OrganizationTypeProfessionalOrg: 'Professional organization'
	};


	constructor(
		public fb: FormBuilder,
		public messageShowService: MessageShowService,
		public systemAdministrationService: SystemAdministrationService,
		public confirmationService: ConfirmationService,
		public router: Router,
		public translateService: TranslateService,
		public localeService: LocaleService,
		public urlService: UrlService
	) {
		super(
			'OrganizationCreateComponent',
			translateService,
			localeService,
			router,
			urlService
		);
	}


	ngOnInit(): void {
		this.initMainForm();
		this.systemAdministrationService.loadStates();
		this.subscribeToPreferedLanguage();
		this.subscribeToStates();
		this.initMenuModel();
		this.initUrl();
	}

	ngOnDestroy(): void {
		this.preferedLanguageSubscription.unsubscribe();
		this.statesSubsciption.unsubscribe();
	}

	initMenuModel() {
		this.organizationTypes = [
			{
				label: 'Select',
				value: null
			},
			{
				label: this.labels.OrganizationTypeICAORO,
				value: SystemAdministrationService.ORGANIZATION_TYPE_ICAORO
			},
			{
				label: this.labels.OrganizationTypeStateCAA,
				value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA
			},
			{
				label: this.labels.OrganizationTypeStateANSP,
				value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP
			},
			{
				label: this.labels.OrganizationTypeStateAOP,
				value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
			},
			{
				label: this.labels.OrganizationTypeInternationalOrg,
				value: SystemAdministrationService.ORGANIZATION_TYPE_INTERNATIONAL_ORG
			},
			{
				label: this.labels.OrganizationTypeUsersOrg,
				value: SystemAdministrationService.ORGANIZATION_TYPE_USERS_ORG
			},
			{
				label: this.labels.OrganizationTypeProfessionalOrg,
				value: SystemAdministrationService.ORGANIZATION_TYPE_PREFESSIONAL_ORG
			}
		];
	}

	subscribeToStates() {
		this.statesSubsciption = this.systemAdministrationService.statesSubjet.subscribe(data => {
			this.states = data;

			if (this.states) {
				this.statesItems = [];
				this.statesItems.push({ label: 'Select', value: null });

				this.states.forEach(item => {
					this.statesItems.push({
						label: item.name,
						value: item.id
					});
				});
			}

		});

		this.systemAdministrationService.emitStatesSubject();
	}

	initMainForm() {
		this.mainForm = this.fb.group({
			shortName: [''],
			pirgRegion: [''],
			name: ['', [Validators.required]],
			type: ['', [Validators.required]],
			state: ['', []],
			address: ['', [Validators.required]],
			email: ['', [Validators.required]],
			website: ['', [Validators.required]],
			phone: ['', [Validators.required]],
			fax: ['', [Validators.required]]
		});

		this.showStates = true;
	}

	onTypeChange() {
		const type = this.mainForm.value.type;
		if (
			[SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA,
			SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP,
			SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
			].includes(type)) {
			this.showStates = true;
		} else {
			this.showStates = false;
		}
		if (SystemAdministrationService.ORGANIZATION_TYPE_ICAORO) {
			this.isRegionalOffice = true;
		} else {
			this.isRegionalOffice = false;
		}

	}

	onCancel() {
		this.router.navigate([this.ORGANIZATION_VIEW]);
	}

	onSave() {
		if (this.mainForm.valid) {
			this.executeSave();
		}
	}

	executeSave() {
		const type = this.mainForm.value.type;
		const organization: Organization = {
			id: null,
			shortName: this.mainForm.value.shortName,
			pirgRegion: this.mainForm.value.pirgRegion,
			name: this.mainForm.value.name,
			type: this.mainForm.value.type,
			state: [SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA,
			SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP,
			SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
			].includes(type) ?
				this.states.find(state => state.id === this.mainForm.value.state) : null,

			address: this.mainForm.value.address,
			email: this.mainForm.value.email,
			website: this.mainForm.value.website,
			phone: this.mainForm.value.phone,
			fax: this.mainForm.value.fax,
		};

		this.systemAdministrationService.createOrganization(organization).then(() => {
			this.systemAdministrationService.loadOrganizations();
			this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
		}, () => {
			this.messageShowService.showWarning(this.labels.SaveFailedMessage);
		});

		this.router.navigate([this.ORGANIZATION_VIEW]);
	}

}
