import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from './app.component';
import { AuthenticationService } from './security/authentication.service';
import { Subscription } from 'rxjs';
import { Authentication } from './security/model/authentication';
import { LocaleService } from './shared/services/locale.service';
import { LocaleLanguage } from './reporting-areas/model/locale-language';
import { Router } from '@angular/router';

@Component({
    selector: 'app-inline-menu',
    templateUrl: './app.inlinemenu.component.html',
    animations: [
        trigger('menu', [
            state('hiddenAnimated', style({
                height: '0px',
                paddingBottom: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
                overflow: 'visible'
            })),
            state('visible', style({
                opacity: 1,
                'z-index': 100
            })),
            state('hidden', style({
                opacity: 0,
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('visible => hidden', animate('.1s linear')),
            transition('hidden => visible', [style({ transform: 'scaleY(0.8)' }), animate('.12s cubic-bezier(0, 0, 0.2, 1)')])
        ])
    ]
})
export class AppInlineMenuComponent implements OnInit, OnDestroy {

    @Input() key = 'inline-menu';

    @Input() style: any;

    @Input() styleClass: string;

    active: boolean;

    USER_PWD = '/system-administration/user-pwd';
    USER_PROFILE = '/system-administration/user-profile';

    authentication: Authentication;
    authenticationSubscription: Subscription;

    languages: LocaleLanguage[];
    languagesSubsciption: Subscription;

    constructor
        (
            public appMain: AppMainComponent,
            public app: AppComponent,
            public router: Router,
            public localeService: LocaleService,
            public authenticationService: AuthenticationService
        ) { }

    ngOnInit(): void {
        this.subscribeAuthentication();
        this.subscribeToLanguages();
    }

    ngOnDestroy(): void {
        this.languagesSubsciption.unsubscribe();
        this.authenticationSubscription.unsubscribe();
    }

    subscribeToLanguages() {
        this.languagesSubsciption = this.localeService.languagesSubject.subscribe(data => {
            this.languages = data;
        });

        this.localeService.emitLanguages();
    }

    subscribeAuthentication() {
        this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
            this.authentication = authentication;
        });
        this.authenticationService.emitAuthenticationSubjctSubjct();
    }

    onClick(event) {
        this.appMain.onInlineMenuClick(event, this.key);
        event.preventDefault();
    }

    get isTooltipDisabled() {
        return !(this.appMain.isSlim() && !this.appMain.isMobile());
    }

    get tabIndex() {
        return !this.appMain.inlineMenuActive ? '-1' : null;
    }

    isHorizontalActive() {
        return this.appMain.isHorizontal() && !this.appMain.isMobile();
    }

    swichLanguage(locale: string) {
        this.localeService.setLocale(locale);
    }

    onChangePassword() {
        this.router.navigate([this.USER_PWD]);
    }

    onEditProfile() {
        this.router.navigate([this.USER_PROFILE]);
    }
}
