import { Component, OnInit, OnDestroy } from '@angular/core';
import { Role } from '../model/role';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Subscription } from 'rxjs';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-role-update',
  templateUrl: './role-update.component.html',
  styleUrls: ['./role-update.component.scss']
})
export class RoleUpdateComponent extends Translatable implements OnInit, OnDestroy {


  ROLE_CREATE = '/system-administration/role-create';
  ROLE_UPDATE = '/system-administration/role-update';
  ROLE_VIEW = '/system-administration/role-view';

  mainForm: FormGroup;

  selectedRole: Role;
  selectedRoleSubsciption: Subscription;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    UpdateRole: 'Update Role',
    RoleCode: 'Role code',
    Label: 'Label',
    Description: 'Description',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'RoleUpdateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.subscribeToPreferedLanguage();
    this.subscribeToSelectedRole();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
  }

  subscribeToSelectedRole() {
    this.selectedRoleSubsciption = this.systemAdministrationService.selectedRoleSubjet.subscribe(data => {
      this.selectedRole = data;

      this.fillForm();

    });

    this.systemAdministrationService.emitSelectedRoleSubject();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      roleCode: new FormControl({ value: '', disabled: true }),
      label: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

  }

  fillForm() {
    this.mainForm.patchValue({
      roleCode: this.selectedRole.roleCode,
      label: this.selectedRole.label,
      description: this.selectedRole.description
    });
  }

  onCancel() {
    this.router.navigate([this.ROLE_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    this.selectedRole.label = this.mainForm.value.label;
    this.selectedRole.description = this.mainForm.value.description;

    this.systemAdministrationService.updateRole(this.selectedRole).then(() => {
      this.systemAdministrationService.loadRoles();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });

    this.router.navigate([this.ROLE_VIEW]);
  }
}