import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ReportingAreasService } from '../reporting-areas.service';
import { MinReportingArea } from '../model/min-reporting-area';
import { TechnicalArea } from '../model/technical-area';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { LocaleLanguage } from '../model/locale-language';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { ToLocalePipe } from 'src/app/shared/pipes/to-locale.pipe';
import { UrlService } from 'src/app/shared/services/url.service';
import { TranslationService } from 'src/app/shared/services/translation.service';

@Component({
  selector: 'app-min-reporting-area-create',
  templateUrl: './min-reporting-area-create.component.html',
  styleUrls: ['./min-reporting-area-create.component.scss']
})
export class MinReportingAreaCreateComponent extends Translatable implements OnInit, OnDestroy {

  MIN_REPORTING_AREA_CREATE = '/reporting-areas/min-reporting-area-create';
  MIN_REPORTING_AREA_UPDATE = '/reporting-areas/min-reporting-area-update';
  MIN_REPORTING_AREA_VIEW = '/reporting-areas/min-reporting-area-view';

  technicalAreas: TechnicalArea[];
  selectedTechnicalArea: TechnicalArea;

  technicalAreaItems: SelectItem[];

  technicalAreasSubsciption: Subscription;

  mainForm: FormGroup;
  languages: LocaleLanguage[];
  languagesSubsciption: Subscription;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    AddMininumReportingArea: 'Add Mininum reporting Area',
    ID: 'ID',
    TechnicalArea: 'Technical Area',
    Reference: 'Reference',
    Details: 'Details',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !',
  };

  constructor(
    public fb: FormBuilder,
    public reportingAreasService: ReportingAreasService,
    public router: Router,
    public toLocalePipe: ToLocalePipe,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService,
    public translationService: TranslationService
  ) {
    super(
      'MinReportingAreaCreateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit(): void {
    this.subscribeToLanguages();
    this.reportingAreasService.loadTechnicalAreas();
    this.subscribeToPreferedLanguage();
    this.initMainForm();
    this.subscribeToTechnicalAreas();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.technicalAreasSubsciption.unsubscribe();
    this.languagesSubsciption.unsubscribe();
  }

  subscribeToLanguages() {
    this.languagesSubsciption = this.localeService.languagesSubject.subscribe(data => {
      this.languages = data;
    });

    this.localeService.emitLanguages();
  }


  subscribeToTechnicalAreas() {
    this.technicalAreasSubsciption = this.reportingAreasService.technicalAreasSubjet.subscribe(data => {
      this.technicalAreas = data;

      if (this.technicalAreas) {
        this.technicalAreaItems = [];
        this.technicalAreaItems.push({ label: 'Select', value: null });

        this.technicalAreas.forEach(area => {
          this.technicalAreaItems.push({
            label: area.areaCode + ' - ' + this.toLocalePipe.transform(area.name, this.preferedLanguage.locale),
            value: area.id
          });
        });
      }

    });

    this.reportingAreasService.emitTechnicalAreasSubject();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      technicalArea: ['', [Validators.required]],
      reference: ['', [Validators.required]],
      localeDetails: this.fb.array([])
    });

    this.languages.forEach(language => {
      this.addLocaleDetails(language.locale);
    });
  }


  get localeDetails() {
    return this.mainForm.get('localeDetails') as FormArray;
  }

  addLocaleDetails(loc: string) {
    this.localeDetails.push(
      this.fb.group({
        locale: [loc],
        description: [''],
        remarks: ['']
      })
    );
  }

  async onTranlate() {

    // retrieve en and fr controls
    let enControl: AbstractControl;
    let frControl: AbstractControl;
    const en = 'en';
    const fr = 'fr';
    for (const control of this.localeDetails.controls) {
      const loc = control.value.locale;
      if (loc === en) {
        enControl = control;
      } else if (loc === fr) {
        frControl = control;
      }
    }

    const translatableControls = [
      { sourceControl: enControl, sourceLang: en, targetControl: frControl, targetLang: fr },
      { sourceControl: frControl, sourceLang: fr, targetControl: enControl, targetLang: en }
    ];

    const translatableProperties = [
      'description',
      'remarks'
    ];

    // translate English non-empty control fields

    translatableControls.forEach(translatable => {
      translatableProperties.forEach(property => {
        if (translatable?.sourceControl?.value[property] && !translatable?.targetControl?.value[property]) {
          this.translationService.translate(translatable.sourceControl.value[property], translatable.sourceLang, translatable.targetLang).then(res => {
            translatable.targetControl.patchValue({
              [property]: res
            });
          }, error => {
            console.error(error);
          });
        }
      });

    });
  }

  onCancel() {
    this.router.navigate([this.MIN_REPORTING_AREA_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.onTranlate().then(() => {
        this.executeSave();
      });
    }
  }

  executeSave() {
    const minReporting: MinReportingArea = {
      id: null,
      technicalArea: this.technicalAreas.find(area => area.id === this.mainForm.value.technicalArea),
      reference: this.mainForm.value.reference,
      description: new Map<string, string>(),
      remarks: new Map<string, string>()
    };

    for (const formValue of this.localeDetails.value) {
      if (formValue.description) {
        minReporting.description[formValue.locale] = formValue.description;
        minReporting.remarks[formValue.locale] = formValue.remarks;
      }
    }

    this.reportingAreasService.createMinreportingArea(minReporting).then(() => {
      this.reportingAreasService.loadMinreportingAreas();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });
    this.router.navigate([this.MIN_REPORTING_AREA_VIEW]);
  }

}
