<div class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <h5>{{labels.ChangePassword}}</h5>
            <div>
                <p style="height: 20px;"></p>
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSave()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="currentPassword" type="password" pInputText formControlName="currentPassword">
                            <label for="currentPassword">{{labels.CurrentPassword}}</label>
                        </span>
                    </div>
                    <div formGroupName="newPasswordGroup" class="p-fluid p-formgrid p-grid p-md-8 p-pt-0">
                        <div class="p-field p-col-12 p-md-6">
                            <span class="p-float-label">
                                <input id="newPassword" type="password" pInputText formControlName="newPassword">
                                <label for="newPassword">{{labels.NewPassword}}</label>
                            </span>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <span class="p-float-label">
                                <input id="confirmPassword" type="password" pInputText
                                    formControlName="confirmPassword">
                                <label for="confirmPassword">{{labels.ConfirmPassword}}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="{{labels.Cancel}}" (click)="onCancel()"
                        icon="pi pi-times" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="{{labels.Save}}" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>
</div>
