import { Directive, HostBinding, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appPrintA3]'
})
export class PrintA3Directive implements AfterViewInit, OnDestroy {

  @HostBinding('class.print-a3') private printA3 = true;
  private style: HTMLStyleElement;

  public ngAfterViewInit() {
    this.style = document.createElement('style');
    this.style.type = 'text/css';
    this.style.innerText = `

      @page {
        size: auto;
        padding: 0; 
        margin: 0;
      }
      

    @media print {
            
      body * {            
        visibility: hidden ;
      } 

      body {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0; 
        padding: 0;
        height: auto; 
        background: none;
        display: inline-block; 
        box-sizing: border-box;
      }
 
      .print-a3, .print-a3 * {
        visibility: visible;        
      } 

      .print-a3, .print-a3 .page {
          position: static;
          left: 0;
          top: 0; 
          background: white;
          display: inline-block;
          box-shadow: none;
      }

      .print-a3 {   
        position: absolute;
        left: 0;
        top: 0; 
        margin: 0;
        padding: 0;
        height: auto; 
        background: none;   
        display: inline-block;
        box-sizing: border-box;        
        overflow: hidden;
      }

    }
    `;

    document.head.appendChild(this.style);
  }

  public ngOnDestroy() {
    document.head.removeChild(this.style);
  }


}
