
<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height: 75vmin;">

            <h5>{{labels.MinimumReportingAreas}}</h5>
            <p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text"
                rejectButtonStyleClass="p-button-text"></p-confirmDialog>
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button *ngIf="isEditingUser" pButton pRipple label="{{labels.New}}" icon="pi pi-plus"
                        class="p-button-success p-mr-2 p-mb-2" (click)="onAdd()"></button>
                    <button *ngIf="isEditingUser" pButton type="button" label="{{labels.Update}}" icon="pi pi-pencil" class="p-button-info p-mr-2 p-mb-2"
						(click)="updateSelectedItem()" [disabled]="!selectedMinReportingArea"></button>
					<button pButton type="button" label="{{labels.Report}}" icon="pi pi-fw pi-calendar-minus" class="p-button-warning p-mr-2 p-mb-2"
                        (click)="onReportDeficiency()" [disabled]="!selectedMinReportingArea"></button>
                </ng-template>

                <ng-template pTemplate="right" >
                    <button *ngIf="isEditingUser" pButton pRipple label="{{labels.Delete}}" icon="pi pi-trash" class="p-button-danger p-mb-2"
                        (click)="deleteSelectedItem()" [disabled]="!selectedMinReportingArea"></button>
                </ng-template>
            </p-toolbar>

            <p-accordion (onOpen)="onTabOpen($event)">
                <p-accordionTab *ngFor="let area of minReportingAreasData; let i=index" [selected]="tabSelected(i)">
                    <ng-template pTemplate="header">
                        <span style="color: blue;">{{area?.technicalArea?.areaCode}} -
                            {{area?.technicalArea?.name | toLocale: preferedLanguage.locale}}</span>
                    </ng-template>
                    <p-table #dtc [value]="area.minReportingAreas" [(selection)]="selectedMinReportingArea"
                        [contextMenu]="cm" dataKey="id" selectionMode="single"
                        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                        [scrollable]="true" scrollHeight="400px" [filterDelay]="0"
                        [globalFilterFields]="['technicalArea.areaCode','reference','description','remarks']">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                                <span></span>
                                <span class="p-input-icon-left p-float-label">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                                    <label class="global-filter-label">{{labels.Search}}</label>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="background-color: lightgray;">{{labels.Area}}</th>
                                <th pSortableColumn="reference" style="background-color: lightgray;">
                                    {{labels.Reference}}
                                    <p-sortIcon field="reference"></p-sortIcon>
                                </th>
                                <th pSortableColumn="description" style="background-color: lightgray;">
                                    {{labels.Description}}
                                    <p-sortIcon field="description"></p-sortIcon>
                                </th>
                                <th pSortableColumn="remarks" style="background-color: lightgray;">{{labels.Remarks}}
                                    <p-sortIcon field="remarks"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-minReportingArea>
                            <tr [pSelectableRow]="minReportingArea" [pContextMenuRow]="minReportingArea" style="height: 50px">

                                <td>
                                    <span
                                        class="p-column-title">{{labels.Area}}</span>{{minReportingArea.technicalArea.areaCode}}

                                </td>
                                <td>
                                    <span
                                        class="p-column-title">{{labels.Reference}}</span>{{minReportingArea.reference}}

                                </td>
                                <td>
                                    <span
                                        class="p-column-title">{{labels.Description}}</span>{{minReportingArea.description | toLocale: preferedLanguage.locale}}

                                </td>
                                <td>
                                    <span
                                        class="p-column-title">{{labels.Remarks}}</span>{{minReportingArea.remarks | toLocale: preferedLanguage.locale}}

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">{{labels.Nodata}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
					
                </p-accordionTab>
            </p-accordion>
			<p-contextMenu #cm [model]="contextMenuItems" [style]="{'width':'200px'}"></p-contextMenu>

        </div>
    </div>
</div>
