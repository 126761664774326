import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { Subscription } from 'rxjs';
import { Authentication } from './security/model/authentication';
import { AuthenticationService } from './security/authentication.service';
import { LocaleService } from './shared/services/locale.service';
import { LocaleLanguage } from './reporting-areas/model/locale-language';
import { ConfigService } from './shared/services/config.service';

@Component({
	selector: 'app-footer',
	templateUrl: './app.footer.component.html'
	/* template: `
		<div class="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2">
			<button onclick="window.open({{userManual}},'_blank')" pButton title="AANDD User guide"
			pRipple type="button" icon="pi pi-book fs-large"
			class="p-button-rounded p-button-text p-button-plain"></button>
			<button pButton title="Tel: +221 33 869.24.24 \nFax: +221 33 820.32.59" pRipple type="button" icon="pi pi-phone fs-large" class="p-button-rounded p-button-text p-button-plain" [ngClass]="{'p-ml-auto p-mr-2': !app.isRTL, 'p-ml-2 p-mr-auto': app.isRTL}"></button>
			<a href="mailto:icaowacaf@icao.int"><button pButton pRipple type="button" icon="pi pi-envelope fs-large" class="p-button-rounded p-button-text p-button-plain" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></button></a>
			<button onclick="window.open('https://www.icao.int/WACAF/Pages/default.aspx','_blank')" pButton pRipple type="button" icon="pi pi-globe fs-large" class="p-button-rounded p-button-text p-button-plain" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></button>
		</div>
	` */
})
export class AppFooterComponent implements OnInit, OnDestroy {

	userManual = 'assets/docs/AANDD-USER-MANUAL-OTHER-EN.pdf';

	copyright = ConfigService.settings.copyright;

	authentication: Authentication;
	authenticationSubscription: Subscription;

	preferedLanguage: LocaleLanguage;
	preferedLanguageSubscription: Subscription;

	constructor(
		public app: AppComponent,
		public authenticationService: AuthenticationService,
		public localeService: LocaleService
	) { }


	subscribeAuthentication() {
		this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
			this.authentication = authentication;
			if (this.authentication && this.preferedLanguage) {
				this.setUserManual();
			}
		});
		this.authenticationService.emitAuthenticationSubjctSubjct();
	}


	// Subscribe to locale, to get upadted in the event of Prefered Language change
	subscribeToPreferedLanguage() {
		this.preferedLanguageSubscription = this.localeService.preferedLanguageSubject.subscribe(data => {
			this.preferedLanguage = data;
			if (this.authentication && this.preferedLanguage) {
				this.setUserManual();
			}
		});
		this.localeService.emitPreferedLanguage();
	}

	ngOnInit() {
		this.subscribeAuthentication();
		this.subscribeToPreferedLanguage();
	}

	ngOnDestroy(): void {
		this.authenticationSubscription.unsubscribe();
	}

	setUserManual() {
		this.userManual = this.authentication.hasAdminRole ||
			this.authentication.hasIcaoDRDRole ||
			this.authentication.hasIcaoRDRole ||
			this.authentication.hasIcaoRORole ? 'assets/docs/AANDD-USER-MANUAL-RO-EN.pdf' :
			this.authentication.hasStateUserRole ? 'assets/docs/AANDD-USER-MANUAL-STATE-' + (this.preferedLanguage.locale === 'fr' || this.preferedLanguage.locale === 'FR' ? 'FR.pdf' : 'EN.pdf') :
				'assets/docs/AANDD-USER-MANUAL-OTHER-EN.pdf';
	}

	openManual() {
		window.open(this.userManual, '_blank');
	}
}
