<div class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <h5>{{labels.AddUser}}</h5>
            <div>
                <p style="height: 20px;"></p> 
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSave()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="email" type="text" pInputText formControlName="email">
                            <label for="email">{{labels.Email}}</label>
                        </span>
                    </div>
                    
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="firstName" type="text" pInputText formControlName="firstName">
                            <label for="firstName">{{labels.FirstName}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <span class="p-float-label">
                            <input id="lastName" type="text" pInputText formControlName="lastName">
                            <label for="lastName">{{labels.LastName}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="title" type="text" pInputText formControlName="title">
                            <label for="title">{{labels.Title}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-dropdown id="organization" [options]="organizationsItems"
                                formControlName="organization">
                            </p-dropdown>
                            <label for="organization">{{labels.Organization}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-multiSelect id="roles" [options]="rolesItems"
                                formControlName="roles">
                            </p-multiSelect>
                            <label for="roles">{{labels.Roles}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-dropdown id="status" [options]="statusListItems"
                                formControlName="status">
                            </p-dropdown>
                            <label for="status">{{labels.Status}}</label>
                        </span>
                    </div>                    
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="{{labels.Cancel}}" (click)="onCancel()"
                        icon="pi pi-times" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="{{labels.Save}}" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>
</div>