import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SystemAdministrationRoutingModule } from './system-administration-routing.module';
import { StateViewComponent } from './state-view/state-view.component';
import { StateCreateComponent } from './state-create/state-create.component';
import { StateUpdateComponent } from './state-update/state-update.component';
import { SharedModule } from '../shared/shared.module';
import { OrganizationViewComponent } from './organization-view/organization-view.component';
import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './organization-update/organization-update.component';
import { RoleViewComponent } from './role-view/role-view.component';
import { RoleCreateComponent } from './role-create/role-create.component';
import { RoleUpdateComponent } from './role-update/role-update.component';
import { UserViewComponent } from './user-view/user-view.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { UserPwdComponent } from './user-pwd/user-pwd.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { UserPwdForgotComponent } from './user-pwd-forgot/user-pwd-forgot.component';
import { UserRegisteredViewComponent } from './user-registered-view/user-registered-view.component';
import { UserRegisteredUpdateComponent } from './user-registered-update/user-registered-update.component';
import { DeficiencyTableComponent } from './deficiency-table/deficiency-table.component';


@NgModule({
  declarations: [
    StateViewComponent,
    StateCreateComponent,
    StateUpdateComponent,
    OrganizationViewComponent,
    OrganizationCreateComponent,
    OrganizationUpdateComponent,
    RoleViewComponent,
    RoleCreateComponent,
    RoleUpdateComponent,
    UserViewComponent,
    UserCreateComponent,
    UserUpdateComponent,
    UserPwdComponent,
    UserProfileComponent,
    UserRegistrationComponent,
    UserPwdForgotComponent,
    UserRegisteredViewComponent,
    UserRegisteredUpdateComponent,
    DeficiencyTableComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    SystemAdministrationRoutingModule
  ],
  exports: [
    UserRegistrationComponent
  ]
})
export class SystemAdministrationModule { }
