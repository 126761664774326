import { NgModule } from '@angular/core';

import { ReportingAreasRoutingModule } from './reporting-areas-routing.module';
import { TechnicalAreaCreateComponent } from './technical-area-create/technical-area-create.component';
import { TechnicalAreaUpdateComponent } from './technical-area-update/technical-area-update.component';
import { TechnicalAreaViewComponent } from './technical-area-view/technical-area-view.component';
import { MinReportingAreaViewComponent } from './min-reporting-area-view/min-reporting-area-view.component';
import { MinReportingAreaCreateComponent } from './min-reporting-area-create/min-reporting-area-create.component';
import { MinReportingAreaUpdateComponent } from './min-reporting-area-update/min-reporting-area-update.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    TechnicalAreaCreateComponent,
    TechnicalAreaUpdateComponent,
    TechnicalAreaViewComponent,
    MinReportingAreaViewComponent,
    MinReportingAreaCreateComponent,
    MinReportingAreaUpdateComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ReportingAreasRoutingModule
  ]
})
export class ReportingAreasModule { }
