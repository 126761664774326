import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'capitalFirst'
})
export class CapitalFirstPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		const lower = value ? value.toLowerCase() : '';
		const firt = lower.length > 0 ? lower[0].toUpperCase() : '';
		const tail = lower.length > 1 ? lower.slice(1) : '';
		return firt + tail;
	}

}
