<!-- <div class="rtr" [ngStyle]="tableColumns?.style">
    <div class="rth" *ngFor="let cell of tableColumns?.cells" [ngStyle]="cell.style">
        <span *ngFor="let cellcontent of cell.cellContents">
            <span *ngIf="cellcontent.contentType === 'CELL_CONTENT_TEXT'"
                [ngStyle]="cellcontent.text?.style">{{cellcontent.text?.content}}</span>
        </span>
      </div>
</div> -->

<!-- <tr class="rtr" [ngStyle]="tableColumns?.style">
    <th class="rth" *ngFor="let cell of tableColumns?.cells" [ngStyle]="cell.style">
        <span *ngFor="let cellcontent of cell.cellContents">
            <span *ngIf="cellcontent.contentType === 'CELL_CONTENT_TEXT'"
                [ngStyle]="cellcontent.text?.style">{{cellcontent.text?.content}}</span>
        </span>
    </th>
</tr> -->

<col *ngFor="let cell of tableColumns?.cells" [ngStyle]="cell.style" />
