<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height: 82vmin;">

            <h5 style="color: gray;">{{labels.DeficienciesData}}</h5>
            <p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text"
                rejectButtonStyleClass="p-button-text"></p-confirmDialog>
            <p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="{{labels.ActionClearDeficienciesTable}}" icon="pi pi-refresh" class="p-button-danger"
                        (click)="onReinitialize()" [disabled]="isProduction"  *ngIf="isDefaultAdminUser"></button>
                </ng-template>
            </p-toolbar>

            <p-splitter [panelSizes]="[75,25]" layout="vertical">
                <ng-template pTemplate>
                    <p-tabView orientation="left">
                        <p-tabPanel [header]="labels.DeficienciesData">
                            <p-table [value]="deficienciesData" 
                                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                                [scrollable]="true" scrollHeight="400px" [filterDelay]="0">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="background-color: lightgray;">
                                            {{labels.Attribute}}
                                        </th>
                                        <th style="background-color: lightgray; text-align: center;">
                                            {{labels.Value}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-deficiency>
                                    <tr style="height: 50px;">
                                        <td>
                                            <span
                                                class="p-column-title">{{labels.Attribute}}</span>{{deficiency.attribute}}
                                        </td>
                                        <td style="text-align: center;">
                                            <span
                                                class="p-column-title">{{labels.Value}}</span>{{deficiency.value}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">No data</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>
                </ng-template>
            </p-splitter>
        </div>
    </div>
</div>
