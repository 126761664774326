<div class="p-grid table-demo">
	<div class="p-col-12">
		<div class="card" style="min-height: 75vmin;">

			<h5>{{labels.States}}</h5>
			<p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                             [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
			<p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple [label]="labels.New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="onAdd()"></button>
                    <button pButton type="button" [label]="labels.Update" icon="pi pi-pencil" class="p-button-info" (click)="updateSelectedItem()" [disabled]="!selectedState"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple [label]="labels.Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" (click)="deleteSelectedItem()" [disabled]="!selectedState"></button>
                </ng-template>
            </p-toolbar>
			
			<p-table #dtc [value]="states" [(selection)]="selectedState" dataKey="id" selectionMode="single"
					 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                     [scrollable]="true" scrollHeight="55vmin" [filterDelay]="0" 
                     [globalFilterFields]="['id','countryCode','name','officialLanguage.language','icaoLanguage.language','regionalOffice.name']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						<span></span>
						<span class="p-input-icon-left p-float-label">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                            <label class="global-filter-label">{{labels.Search}}</label>
                        </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
                        <th pSortableColumn="countryCode" style="background-color: lightgray;">{{labels.CountryCode}}
							<p-sortIcon field="countryCode"></p-sortIcon>
						</th>
						<th pSortableColumn="name" style="background-color: lightgray;">{{labels.Name}}
							<p-sortIcon field="name"></p-sortIcon>
						</th>
						<th pSortableColumn="officialLanguage" style="background-color: lightgray;">{{labels.OfficialLanguage}}
							<p-sortIcon field="officialLanguage"></p-sortIcon>
                        </th>
                        <th pSortableColumn="icaoLanguage" style="background-color: lightgray;">{{labels.IcaoLanguage}}
							<p-sortIcon field="icaoLanguage"></p-sortIcon>
                        </th>
                        <th pSortableColumn="regionalOffice" style="background-color: lightgray;">{{labels.RegionalOffice}}
							<p-sortIcon field="regionalOffice"></p-sortIcon>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-state>
					<tr [pSelectableRow]="state" style="height: 50px">
                        <td>
							<span class="p-column-title">{{labels.CountryCode}}</span>{{state.countryCode}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Name}}</span>{{state.name}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.OfficialLanguage}}</span>{{state.officialLanguage.language}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.IcaoLanguage}}</span>{{state.icaoLanguage.language}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.RegionalOffice}}</span>{{state.regionalOffice.name}}
							
                        </td>						
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">{{labels.Nodata}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
    </div>
</div>