import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Role } from '../model/role';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent extends Translatable implements OnInit, OnDestroy {


  ROLE_CREATE = '/system-administration/role-create';
  ROLE_UPDATE = '/system-administration/role-update';
  ROLE_VIEW = '/system-administration/role-view';

  mainForm: FormGroup;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    AddRole: 'Add Role',
    RoleCode: 'Role code',
    Label: 'Label',
    Description: 'Description',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'RoleCreateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.subscribeToPreferedLanguage();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
  }


  initMainForm() {
    this.mainForm = this.fb.group({
      roleCode: ['', [Validators.required]],
      label: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

  }

  onCancel() {
    this.router.navigate([this.ROLE_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    const role: Role = {
      id: null,
      roleCode: this.mainForm.value.roleCode,
      label: this.mainForm.value.label,
      description: this.mainForm.value.description
    };

    this.systemAdministrationService.createRole(role).then(() => {
      this.systemAdministrationService.loadRoles();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });

    this.router.navigate([this.ROLE_VIEW]);
  }
}
