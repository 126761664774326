import { Component, OnInit, Input, ElementRef, AfterViewInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ReportDocumentBody } from '../../model/report-document-body';
import { ReportDocumentParagraph } from '../../model/report-document-paragraph';

@Component({
  selector: 'app-document-viewer-body',
  templateUrl: './document-viewer-body.component.html',
  styleUrls: ['./document-viewer-body.component.css']
})
export class DocumentViewerBodyComponent implements OnInit, AfterViewInit {

  @Input() reportBody: ReportDocumentBody;
  @Input() heightLimit: number;
  @Output() remaingBodyOut = new EventEmitter<ReportDocumentBody>();
  bodyHeight: number;
  paragraphHeights: number[];
  bodyOffsetHeights: number[];

  remainingBody: ReportDocumentBody;
  remainingFirstIndexe: number;

  @ViewChild('bodyDiv')
  bodyDiv: ElementRef;

  constructor() { }

  ngOnInit() {
    this.bodyOffsetHeights = [];
    this.bodyOffsetHeights.push(this.heightLimit);
  }

  ngAfterViewInit(): void {
    this.checkElementHeight();
  }

  checkElementHeight() {
    const div = this.bodyDiv.nativeElement as HTMLDivElement;
    this.bodyHeight = div.offsetHeight;

    // set body height to heightLimit
    div.style.height = '' + this.heightLimit + 'px';
  }



  /* 	Handle reporting paragraph height
	  * Add the reported height to [beforeParagraphHeights] and update the bodyOffsetHeights;
	  * Upon handling the last paragraph of this page body, report eventually remainingBody
    */
  handleParagraphHeightOut(event: { index: number, paragraphHeight: number }) {
    if (!this.paragraphHeights) { this.paragraphHeights = []; }
    this.paragraphHeights.push(event.paragraphHeight);

    const totalHeight = this.paragraphHeights.reduce((a, b) => a + b);
    const offset = this.heightLimit - totalHeight;
    this.bodyOffsetHeights.push(offset);
  }


  /* 	Handle reported remaining paragraph
	  *  Create remaining page body [remainingBody] to hold paragraphs
	  * Upon handling the last paragraph of this page body, report eventually remainingBody
  	*/
  handleremaingParagraphOut(event: {index: number, paragraph: ReportDocumentParagraph}) {
    if (!this.remainingBody) { this.remainingBody = new ReportDocumentBody(); }
    if (!this.remainingBody.paragraphs) { this.remainingBody.paragraphs = []; }
    this.remainingBody.paragraphs.push(event.paragraph);

    if (!this.remainingFirstIndexe) { this.remainingFirstIndexe = event.index; }

    if (event.index === (this.reportBody.paragraphs.length - 1)) {
      const emptyParagraph = new ReportDocumentParagraph();
      emptyParagraph.contentType = 'PARAGRAPH_CONTENT_TEXT';
      this.remainingBody.paragraphs.unshift(emptyParagraph);
      this.remaingBodyOut.emit(this.remainingBody);
    }
  }

}
