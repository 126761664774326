import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppComponent } from 'src/app/app.component';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Router } from '@angular/router';
import { Authentication } from '../model/authentication';
import { UrlService } from 'src/app/shared/services/url.service';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
	selector: 'app-login-auth',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	SIGNUP = '/signup';
	PASSWORDFORGOT = 'pwdforgot';
	ASBU_DASHBOARD = '/asbu/asbu-dashboard';
	ASBU_HOME = '/asbu/asbu-home';
	AANDD_DASHBOARD = '/deficiencies/dashboard';
	STATES_VIEW = '/system-administration/state-view';

	copyright = ConfigService.settings.copyright;

	mainForm: FormGroup;

	labels = {
		LoginFailedMessage: 'Failed to login with given credentials !'
	};

	constructor(
		public appMain: AppMainComponent,
		public app: AppComponent,
		public router: Router,
		public authenticationService: AuthenticationService,
		public fb: FormBuilder,
		public messageShowService: MessageShowService,
		public messageService: MessageService,
		public applicationsService: ApplicationsService
	) { }

	ngOnInit(): void {
		this.initMainForm();
	}

	initMainForm() {
		this.mainForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
			remember: [false]
		});
	}

	onLogin() {
		if (this.mainForm.valid) {
			this.executeLogin();
		}
	}

	executeLogin() {
		const credentials = {
			username: this.mainForm.value.email as string,
			password: this.mainForm.value.password as string
		};

		const remember = this.mainForm.value.remember as boolean;

		this.authenticationService.login(credentials, remember)
			.then((authentication: Authentication) => {
				if (authentication.hasDefaultAdminRole) {
					this.router.navigate([this.STATES_VIEW]);
				} else if (this.applicationsService.asbuSelected) {
					this.router.navigate([this.ASBU_HOME]);
				} else if (this.applicationsService.aanddSelected) {
					this.router.navigate([this.AANDD_DASHBOARD]);
				} else {
					this.router.navigate(['/']);
				}
			}, () => {
				this.messageShowService.showLoginError(this.labels.LoginFailedMessage);
			});
	}

	onCloseMessage() {
		this.messageService.clear('loginMsgKey');
	}

	onRegister() {
		this.router.navigate([this.SIGNUP]);
	}

}
