import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ReportDocumentHeader } from '../../model/report-document-header';

@Component({
  selector: 'app-document-viewer-header',
  templateUrl: './document-viewer-header.component.html',
  styleUrls: ['./document-viewer-header.component.css']
})
export class DocumentViewerHeaderComponent implements OnInit, AfterViewInit {

  @Input() reportHeader: ReportDocumentHeader;
  @Output() offsetHeight = new EventEmitter<number>();
  headerHeight: number;

  @ViewChild('headerDiv')
  headerDiv: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkElementHeight();
    });
  }

  checkElementHeight() {
    const div = this.headerDiv.nativeElement as HTMLDivElement;
    this.headerHeight = div.offsetHeight;
    this.offsetHeight.emit(this.headerHeight);
  }

}
