import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { Role } from '../model/role';
import { Subscription } from 'rxjs';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent extends Translatable implements OnInit, OnDestroy {


  ROLE_CREATE = '/system-administration/role-create';
  ROLE_UPDATE = '/system-administration/role-update';
  ROLE_VIEW = '/system-administration/role-view';

  roles: Role[];
  selectedRole: Role;
  coreRoles: string[];

  rolesSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    Roles: 'Roles',
    New: 'New',
    Update: 'Update',
    Delete: 'Delete',
    Search: 'Search',
    RoleCode: 'Role code',
    Label: 'Label',
    Description: 'Description',
    Nodata: 'No data found !',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected role !'
  };


  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'RoleViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.systemAdministrationService.loadRoles();
    this.coreRoles = this.systemAdministrationService.coreRoles;
    this.subscribeToRoles();
    this.subscribeToPreferedLanguage();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.rolesSubsciption.unsubscribe();
  }

  subscribeToRoles() {
    this.rolesSubsciption = this.systemAdministrationService.rolesSubjet.subscribe(data => {
      this.roles = data;
    });

    this.systemAdministrationService.emitRolesSubject();
  }

  get isCoreRoleSelected() {
    return (this.selectedRole !== null && this.selectedRole !== undefined && this.coreRoles.includes(this.selectedRole.roleCode));
  }

  coreStyle(role: string) {
    return this.coreRoles.includes(role) ? { fontStyle: 'italic' } : null;
  }

  deleteSelectedItem() {
    if (this.selectedRole !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.systemAdministrationService.deleteRole(this.selectedRole).then(() => {
            this.systemAdministrationService.loadRoles();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedRole = null;
        },
        reject: () => {
        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedRole !== null) {
      this.systemAdministrationService.selectRole(this.selectedRole);
      this.router.navigate([this.ROLE_UPDATE]);
    }
  }

  onAdd() {
    this.router.navigate([this.ROLE_CREATE]);
  }


}
