<div class="p-grid">
    <!-- RIGHT TOOL BOX MENU-->
    <div class="toolmenu">
        <p-menu [model]="menuItems"></p-menu>
    </div>

    <!-- BEGIN DOCUMENT PAGES -->
    <div class="p-col-12" align="center" id="exportId">
        <app-document-viewer-book [reportDocument]="reportDocument" [size]="size" [layout]="layout"></app-document-viewer-book>
    </div>
    <!-- END DOCUMENT PAGES -->
</div>
