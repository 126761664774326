import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from '../model/user';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-user-pwd',
  templateUrl: './user-pwd.component.html',
  styleUrls: ['./user-pwd.component.scss']
})
export class UserPwdComponent extends Translatable implements OnInit, OnDestroy {


  mainForm: FormGroup;

  authenticatedUser: User;
  authenticatedUserSubscription: Subscription;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    ChangePassword: 'Change password',
    CurrentPassword: 'Current pass word',
    NewPassword: 'New pass word',
    ConfirmPassword: 'Confirm new pass word',
    Cancel: 'Cancel',
    Save: 'Save',
    ChangeSuccesMessage: 'Password changed with success !',
    ChangeFailedMessage: 'Failed to change password, verify that current password is correct'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public authenticationService: AuthenticationService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'UserPwdComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.subscribeToAuthenticatedUser();
    this.subscribeToPreferedLanguage();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.authenticatedUserSubscription.unsubscribe();
  }

  subscribeToAuthenticatedUser() {
    this.authenticatedUserSubscription = this.authenticationService.authenticationSubjct.subscribe(data => {
      this.authenticatedUser = data && data.user ? data.user : null;
    });
    this.authenticationService.emitAuthenticationSubjctSubjct();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      // tslint:disable-next-line: deprecation
      newPasswordGroup: this.fb.group({
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      },
        {
          validator: this.validateConfirm
        }
      )
    });
  }

  onCancel() {
    this.router.navigate([this.urlService.getPreviousUrl()]);
  }

  onSave() {
    if (this.mainForm.valid && this.authenticatedUser) {
      this.executeSave();
    }
  }

  executeSave() {

    const currentPassword = this.mainForm.value.currentPassword;
    const newPassword = this.mainForm.value.newPasswordGroup.newPassword;

    this.systemAdministrationService.changePassword(this.authenticatedUser.email, currentPassword, newPassword).then(() => {
      this.messageShowService.showSuccess(this.labels.ChangeSuccesMessage);
      this.router.navigate([this.urlService.getPreviousUrl()]);
    }, () => {
      this.messageShowService.showWarning(this.labels.ChangeFailedMessage);
    });
  }

  validateConfirm(passwdGroup: FormGroup) {
    const NEW_PASSWORD = passwdGroup.value.newPassword;
    const CONFIRM = passwdGroup.value.confirmPassword;
    return CONFIRM && CONFIRM === NEW_PASSWORD ? null : { valid: false };
  }

}
