import { Directive, HostBinding, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appPrintSection]'
})
export class PrintSectionDirective implements AfterViewInit, OnDestroy {

  @HostBinding('class.print-section') private printSection = true;
  private style: HTMLStyleElement;

  public ngAfterViewInit() {
    this.style = document.createElement('style');
    // tslint:disable-next-line: deprecation
    this.style.type = 'text/css';
    this.style.innerText = `

      @page {
        size: auto;
        padding: 0;
        margin: 0;
      }


    @media print {

      body * {
        visibility: hidden ;
      }

      body {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
		    height: auto;
		    width: auto;
        background: none;
        display: inline-block;
        box-sizing: border-box;
      }

      .print-section, .print-section * {
        visibility: visible;
      }

      .print-section, .print-section .page {
          position: static;
          left: 0;
          top: 0;
          margin-top: 0;
          margin-bottom: 0;
          background: white;
          display: inline-block;
          box-shadow: none;
          box-sizing: border-box;
          overflow: hidden;
      }

      .print-section {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        height: auto;
        background: none;
        display: inline-block;
        box-sizing: border-box;
        overflow: hidden;
      }

    }
    `;

    document.head.appendChild(this.style);
  }

  public ngOnDestroy() {
    document.head.removeChild(this.style);
  }

}
