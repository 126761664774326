<!-- <div #tableDiv class="rtable">
    <app-document-viewer-table-header *ngIf="reportTable?.header" [tableHeader]="reportTable.header">
    </app-document-viewer-table-header>
    <app-document-viewer-table-columnset *ngIf="reportTable?.columns" [tableColumns]="reportTable.columns">
    </app-document-viewer-table-columnset>
    <app-document-viewer-table-row *ngFor="let row of reportTable?.rows, index as index" (tableRowOut)="handleTableRowOut($event)" [rowIndex]="index" [tableRow]="row"></app-document-viewer-table-row>
</div> -->

<table #tableDiv class="rtable" [ngStyle]="reportTable?.style">
    <col *ngFor="let cell of reportTable?.columns?.cells" [ngStyle]="cell.style" />
    <app-document-viewer-table-header *ngIf="reportTable?.header" [tableHeader]="reportTable.header">
    </app-document-viewer-table-header>
    <app-document-viewer-table-row *ngFor="let row of reportTable?.rows, index as index" (tableRowOut)="handleTableRowOut($event)" [rowIndex]="index" [tableRow]="row"></app-document-viewer-table-row>
</table>
